"use client";

import axios from "axios";

const baseURL = process.env.REACT_APP_ADMIN_URL;
export const mediaUploadURL = "https://kiwikiosk.nyc3.digitaloceanspaces.com/media/";

const API = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
    },
});

// Helper function to get the full media URL
export const getMediaURL = (path) => {
    //return path ? `${mediaUploadURL}${path}` : null;
    const baseURL = "https://nyc3.digitaloceanspaces.com/kiwikiosk/media/";
  return baseURL + path;
};

export default API;
