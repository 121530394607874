import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  Collapse,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spacer,
  Spinner,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import API, { getMediaURL } from "../../context/API";
import { TbCategory } from "react-icons/tb";
import { BiCollapseVertical } from "react-icons/bi";
import { Reorder, useDragControls } from "framer-motion";
import { MdDragHandle } from "react-icons/md";

const MenuItem = React.memo(
  ({ item, categoryId, updateItemOrder, onEdit, onDelete }) => {
    const [price, setPrice] = useState(item.price);
    const { colorMode } = useColorMode();

    const handleEditClick = () => {
      onEdit(item);
      console.log(item);
    };

    const handleDeleteClick = () => {
      onDelete(item.id);
    };

    const handlePriceChange = (e) => {
      const newPrice = parseFloat(e.target.value);
      setPrice(newPrice);
      updateItemOrder(categoryId, item.id, newPrice);
    };

    return (
      <Box
        key={item.id}
        mb="8px"
        p={{ base: "4", md: "4" }}
        pl={{ base: "4", md: "16" }}
        borderWidth="1px"
        borderRadius="lg"
        bg={colorMode === "light" ? "white" : "transparent"}
        position="relative"
      >
        <Flex
          justify="space-between"
          align="center"
          direction={{ base: "column", md: "row" }}
        >
          <Flex align={"center"} mb={{ base: "4", md: "0" }}>
            <Image
              src={item.picture}
              boxSize={{ base: "40px", md: "50px" }}
              objectFit="cover"
              borderRadius="md"
              mr="4"
              alt={item.item}
              fallbackSrc="https://via.placeholder.com/50"
            />
            <VStack align={"flex-start"} spacing={{ base: "2", md: "1" }}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                {item.item}
              </Text>
              <Text fontSize={{ base: "xs", md: "sm" }} textAlign={"justify"}>
                {item.description}
              </Text>
            </VStack>
          </Flex>

          <Flex
            w={{ base: "100%", md: "auto" }}
            justifyContent="space-between"
            alignItems="center"
            mt={{ base: "2", md: "0" }}
            direction={{ base: "row", md: "row" }}
          >
            <input
              type="number"
              value={item.price != null ? item.price.toFixed(2) : ""}
              onChange={handlePriceChange}
              style={{
                padding: "5px",
                fontSize: "14px",
                maxWidth: "70px",
                marginRight: "10px",
                borderRadius: "4px",
              }}
              disabled={true}
            />
            <Flex>
              <IconButton
                icon={<EditIcon />}
                size="sm"
                onClick={handleEditClick}
                aria-label="Edit item"
              />
              <IconButton
                icon={<DeleteIcon />}
                size="sm"
                onClick={handleDeleteClick}
                aria-label="Delete item"
                colorScheme="red"
                ml={2}
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    );
  }
);

const MenuCategory = React.memo(
  ({
    category,
    categoryItems,
    isOpen,
    toggleCategory,
    loadingItems,
    updateItemOrder,
    onEdit,
    onDelete,
    query,
  }) => {
    const { colorMode } = useColorMode();
    const arrowIcon = isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;

    const [items, setItems] = useState(categoryItems || []);
    const toast = useToast();

    useEffect(() => {
      setItems(categoryItems || []);
    }, [categoryItems]);

    const handleItemReorder = (newOrder) => {
      // Update the sequence_number based on the new order
      const updatedItems = newOrder.map((item, index) => ({
        ...item,
        sequence_number: index,
      }));
      setItems(updatedItems);
      updateItemOrder(category.id, updatedItems);
      saveItemOrder(updatedItems);
    };

    const saveItemOrder = async (newOrder) => {
      try {
        const updatedItems = newOrder.map((item, index) => ({
          ...item,
          sequence_number: index,
        }));

        await Promise.all(
          updatedItems.map((item) =>
            API.put(`/items/${item.id}`, {
              sequence_number: item.sequence_number,
            })
          )
        );

        toast({
          title: "Items reordered.",
          description: "The items have been reordered successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error updating item sequence numbers:", error);
        toast({
          title: "Error updating item order.",
          description: "Unable to update the item order.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const categoryBottomBorderColor = useColorModeValue("gray.300", "gray.500");

    const dragControls = useDragControls();

    const handleDragStart = (event) => {
      event.stopPropagation();
      dragControls.start(event);
    };

    return (
      <Reorder.Item
        value={category.id}
        dragListener={false}
        dragControls={dragControls}
        style={{ listStyleType: "none" }}
        key={category.id}
      >
        <VStack spacing={4} align="stretch" width={"100%"}>
          <Flex
            justify="space-between"
            align="center"
            p="4"
            bg={colorMode === "light" ? "white" : "gray.800"}
            boxShadow={"md"}
            cursor="pointer"
            onClick={() => toggleCategory(category.id)}
            borderBottomColor={categoryBottomBorderColor}
            borderBottomWidth={"1px"}
            width="100%"
          >
            <Box
              onPointerDown={handleDragStart}
              cursor="grab"
              mr={4}
              borderWidth={"1px"}
              borderColor={colorMode === "dark" ? "gray.700" : "gray.300"}
              borderRadius={"md"}
              px={"4"}
              py={"2"}
            >
              <Icon
                as={MdDragHandle}
                w={{ base: 6, md: 8 }}
                h={{ base: 6, md: 8 }}
              />
            </Box>
            <Flex align="center" flex="1" userSelect={"none"}>
              <VStack align="flex-start" spacing="0" ml={4} userSelect={"none"}>
                <Text fontSize={{ base: "md", md: "xl" }} fontWeight="bold">
                  {category.category}
                </Text>
                <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
                  {categoryItems.length} items
                </Text>
              </VStack>
            </Flex>
            <Box userSelect={"none"}>{arrowIcon}</Box>
          </Flex>
          <Collapse in={isOpen} animateOpacity>
            <Box>
              {loadingItems ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Spinner size="md" />
                </Box>
              ) : (
                <Reorder.Group
                  as="div"
                  axis="y"
                  values={items}
                  onReorder={handleItemReorder}
                >
                  {items.map((item) => (
                    <Reorder.Item as="div" key={item.id} value={item}>
                      <MenuItem
                        key={item.id}
                        item={item}
                        categoryId={category.id}
                        updateItemOrder={updateItemOrder}
                        onEdit={onEdit}
                        onDelete={onDelete}
                      />
                    </Reorder.Item>
                  ))}
                </Reorder.Group>
              )}
            </Box>
          </Collapse>
        </VStack>
      </Reorder.Item>
    );
  }
);

const MenuOverview = ({
  refreshTrigger,
  onEditItem,
  handleAddItem,
  handleSaveItem,
  categoriesToRefetch,
  onCategoriesRefetched,
}) => {
  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [categoryOrder, setCategoryOrder] = useState([]);

  const [areAllCollapsed, setAreAllCollapsed] = useState(true);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryItemsMap, setCategoryItemsMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingItems, setLoadingItems] = useState({});
  const [openedCategories, setOpenedCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandAll, setExpandAll] = useState(false);
  const [allItems, setAllItems] = useState([]);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const suggestionBoxRef = useRef();
  const bgColor = useColorModeValue("white", "gray.800");
  const bgHoverColor = useColorModeValue("gray.200", "gray.700");

  const focusBorderColor = useColorModeValue("gray.700", "gray.200");
  const shadowColor = useColorModeValue("md", "dark-lg");

  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const spacing = useBreakpointValue({ base: "2", md: "4" });

  useOutsideClick({
    ref: suggestionBoxRef,
    handler: () => setIsSearching(false),
  });

  useEffect(() => {
    fetchCategories();
  }, [refreshTrigger]);

  const fetchCategories = async () => {
    try {
      const response = await API.get("/categories");
      const categoriesData = response.data.data.map((category) => ({
        ...category,
        picture: getMediaURL(category.picture),
      }));

      // Sort categories by sequence_number for category reordering
      categoriesData.sort((a, b) => a.sequence_number - b.sequence_number);

      // Fetching items for each category
      const categoriesWithItems = await Promise.all(
        categoriesData.map(async (category) => {
          try {
            const response = await API.get(`/categories/${category.id}/items`);
            const categoryItems = response.data.data;
            const itemIds = categoryItems.map((item) => item.id.toString());
            return {
              ...category,
              items: itemIds,
            };
          } catch (error) {
            console.error(
              `Error fetching items for category ${category.id}:`,
              error
            );
            return {
              ...category,
              items: [],
            };
          }
        })
      );

      setCategories(categoriesWithItems);
      setCategoryOrder(categoriesWithItems.map((cat) => cat.id));
      setFilteredCategories(categoriesWithItems);

      const allCategoryIds = categoriesData.map((cat) => cat.id);
      await Promise.all(
        allCategoryIds.map((categoryId) => fetchCategoryItems(categoryId))
      );

      setOpenedCategories([]);
      setAreAllCollapsed(true);
      setExpandAll(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoryItems = async (categoryId) => {
    setLoadingItems((prev) => ({ ...prev, [categoryId]: true }));
    try {
      const response = await API.get(`/categories/${categoryId}/items`);
      const itemsArray =
        response.data.data || response.data.items || response.data || [];

      const itemsData = Array.isArray(itemsArray)
        ? itemsArray.map((item) => ({
            ...item,
            picture: getMediaURL(item.picture),
          }))
        : [];

      // Sort items by sequence_number for reordering func.
      itemsData.sort((a, b) => a.sequence_number - b.sequence_number);

      setCategoryItemsMap((prevMap) => ({
        ...prevMap,
        [categoryId]: itemsData,
      }));
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoadingItems((prev) => ({ ...prev, [categoryId]: false }));
    }
  };

  useEffect(() => {
    if (categoriesToRefetch && categoriesToRefetch.length > 0) {
      categoriesToRefetch.forEach((categoryId) => {
        fetchCategoryItems(categoryId);
      });
      onCategoriesRefetched();
    }
  }, [categoriesToRefetch]);

  useEffect(() => {
    const combinedItems = categories.reduce((acc, category) => {
      const items = categoryItemsMap[category.id] || [];
      return acc.concat(
        items.map((item) => ({
          ...item,
          categoryId: category.id,
          categoryName: category.category,
        }))
      );
    }, []);
    setAllItems(combinedItems);
  }, [categoryItemsMap, categories]);

  const toggleCategory = (categoryId) => {
    setOpenedCategories((prevOpened) => {
      const isOpened = prevOpened.includes(categoryId);
      // If the category is already opened or if the items are already fetched, just expand the category.
      if (isOpened || categoryItemsMap[categoryId]) {
        return isOpened
          ? prevOpened.filter((id) => id !== categoryId)
          : [...prevOpened, categoryId];
      } else {
        // Only fetch items if they haven't been fetched yet
        fetchCategoryItems(categoryId);
        return [...prevOpened, categoryId];
      }
    });
  };

  const handleReorder = async (newOrder) => {
    setCategoryOrder(newOrder);

    const reorderedCategories = newOrder.map((id, index) => {
      const category = categories.find((cat) => cat.id === id);
      return {
        ...category,
        sequence_number: index + 1,
      };
    });

    setCategories(reorderedCategories);

    try {
      await Promise.all(
        reorderedCategories.map((category) =>
          API.put(`/categories/${category.id}`, {
            id: category.id,
            category: category.category,
            sequence_number: category.sequence_number,
            items: category.items,
          })
        )
      );
      toast({
        title: "Categories reordered.",
        description: "The categories have been reordered successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating sequence numbers:", error);
      toast({
        title: "Error updating order.",
        description: "Unable to update the category order.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toggleAllCategories = () => {
    if (expandAll) {
      setOpenedCategories([]);
      setAreAllCollapsed(true);
    } else {
      const allCategoryIds = categories.map((category) => category.id);
      setOpenedCategories(allCategoryIds);
      setAreAllCollapsed(false);
    }
    setExpandAll(!expandAll);
  };

  const matchingCategories = useMemo(() => {
    return categories.filter((category) =>
      category.category.toLowerCase().includes(query)
    );
  }, [query, categories]);

  const matchingItems = useMemo(() => {
    return allItems.filter((item) => item.item.toLowerCase().includes(query));
  }, [query, allItems]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setQuery(searchTerm);

    if (searchTerm === "") {
      setFilteredCategories(categories);
      setCategoryOrder(categories.map((cat) => cat.id));
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
  };

  // Clear search input and reset filters
  const clearSearch = () => {
    setQuery("");
    setFilteredCategories(categories);
    setIsSearching(false);
    setCategoryOrder(categories.map((cat) => cat.id)); // Reset to original order
  };

  // Handle selecting a suggestion (filter the categories displayed)
  const handleCategorySelect = (categoryId) => {
    const selected = categories.find((category) => category.id === categoryId);
    if (selected) {
      setFilteredCategories([selected]);
      setCategoryOrder([selected.id]);
      setSelectedCategory(selected);
    }
    setIsSearching(false);
  };

  // Handle selecting an item from suggestions
  const handleItemSelect = (itemId) => {
    const selectedItem = allItems.find((item) => item.id === itemId);
    if (selectedItem) {
      const category = categories.find(
        (cat) => cat.id === selectedItem.categoryId
      );
      setFilteredCategories([category]);
      setCategoryOrder([category.id]);
      setSelectedCategory(category);
    }
    setIsSearching(false);
  };

  const confirmDelete = (id) => {
    setSelectedItem(id);
    onDeleteOpen();
  };

  const handleDelete = async () => {
    try {
      await API.delete(`/items/${selectedItem}`);
      setCategoryItemsMap((prevMap) => {
        const updatedMap = { ...prevMap };
        for (const categoryId in updatedMap) {
          updatedMap[categoryId] = updatedMap[categoryId].filter(
            (item) => item.id !== selectedItem
          );
        }
        return updatedMap;
      });
      onDeleteClose();
      toast({
        title: "Item deleted.",
        description: "The item was successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting item:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to delete the item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItemOrder = (categoryId, newOrder) => {
    setCategoryItemsMap((prevMap) => ({
      ...prevMap,
      [categoryId]: newOrder,
    }));
  };

  // Render the suggestion box for searching of both categories and items
  const renderSuggestionBox = () => {
    return (
      <Box
        ref={suggestionBoxRef}
        bg={bgColor}
        borderWidth="1px"
        borderRadius="md"
        shadow={shadowColor}
        zIndex="10"
        position="absolute"
        top="60px"
        width="100%"
        overflowY={"auto"}
        maxHeight={"350px"}
        mt="2"
        px="4"
        py="2"
      >
        <List spacing={2}>
          {matchingCategories.length > 0 || matchingItems.length > 0 ? (
            <>
              {/* Display matching categories */}
              {matchingCategories.map((category) => (
                <ListItem
                  key={`category-${category.id}`}
                  cursor="pointer"
                  p="2"
                  borderRadius="md"
                  _hover={{
                    bg: bgHoverColor,
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => handleCategorySelect(category.id)}
                >
                  <Flex justify="space-between" align="center">
                    <Text>{category.category}</Text>
                    <Text fontSize="sm" color="gray.500">
                      Category
                    </Text>
                  </Flex>
                </ListItem>
              ))}

              {/* Display matching items inside matching categories */}
              {matchingItems.map((item) => (
                <ListItem
                  key={`item-${item.id}-${item.categoryId}`} //  unique key by combining item ID and category ID
                  cursor="pointer"
                  p="2"
                  borderRadius="md"
                  _hover={{
                    bg: bgHoverColor,
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => handleItemSelect(item.id)}
                >
                  <Flex justify="space-between" align="center">
                    <Text>{item.item}</Text>
                    <Text fontSize="sm" color="gray.500">
                      Item in {item.categoryName}
                    </Text>
                  </Flex>
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem>
              <Text textAlign="center" color="gray.500">
                No matching categories or items found
              </Text>
            </ListItem>
          )}
        </List>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <>
        <Flex
          justify={"space-between"}
          alignItems={"center"}
          width={"100%"}
          flexWrap={"wrap"}
        >
          <Box>
            <Heading size={"xl"} letterSpacing={-2}>
              Menu
            </Heading>
            <Text fontSize={"sm"}>
              Menu overview, see different categories and items against these
              categories
            </Text>
          </Box>
        </Flex>

        <Flex width="100%" p={spacing} alignItems="center">
          <Flex width="100%" alignItems="center" position="relative">
            <InputGroup size="md">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Search categories..."
                _placeholder={{ color: "gray.500" }}
                value={query}
                onChange={handleSearchChange}
                onFocus={() => setIsSearching(true)}
                variant={"filled"}
                borderRadius={"full"}
                _focus={{
                  borderColor: focusBorderColor,
                }}
                sx={{
                  "::-webkit-search-cancel-button": {
                    appearance: "none",
                  },
                }}
              />
              <InputRightElement>
                {query && (
                  <CloseButton onClick={clearSearch} borderRadius={"full"} />
                )}
              </InputRightElement>
            </InputGroup>

            {isSearching && query && renderSuggestionBox()}
          </Flex>
          <Spacer />
          <Flex alignItems="flex-end">
            <ButtonGroup>
              <IconButton
                size={buttonSize}
                icon={<BiCollapseVertical />}
                bg="black"
                color={"white"}
                _hover={{
                  color: "black",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "gray.300",
                }}
                variant="outline"
                aria-label={expandAll ? "Collapse All" : "Expand All"}
                ml={spacing}
                onClick={toggleAllCategories}
                mr={0}
                display={{ base: "flex", md: "none" }}
              />

              <Button
                leftIcon={<BiCollapseVertical />}
                bg="black"
                color={"white"}
                _hover={{
                  color: "black",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "gray.300",
                }}
                variant="outline"
                onClick={toggleAllCategories}
                aria-label={expandAll ? "Collapse All" : "Expand All"}
                ml={spacing}
                display={{ base: "none", md: "flex" }}
              >
                {expandAll ? "Collapse All" : "Expand All"}
              </Button>
              <IconButton
                size={buttonSize}
                icon={<AddIcon />}
                bg="black"
                color={"white"}
                _hover={{
                  color: "black",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "gray.300",
                }}
                variant="outline"
                aria-label="Add item"
                ml={spacing}
                onClick={handleAddItem} // Trigger sidebox for adding new item
                mr={0}
              />
            </ButtonGroup>
          </Flex>
        </Flex>
      </>
      <Box p={4} bg={bgColor} borderRadius="md">
        <Reorder.Group
          as="div"
          axis="y"
          values={categoryOrder}
          onReorder={handleReorder}
          style={{ listStyleType: "none" }}
        >
          {categoryOrder.map((id) => {
            const category = filteredCategories.find((cat) => cat.id === id);

            return (
              <Reorder.Item
                as="div"
                key={id}
                value={id}
                style={{ listStyleType: "none" }}
                dragListener={false}
              >
                <MenuCategory
                  category={category}
                  categoryItems={categoryItemsMap[category.id] || []}
                  isOpen={openedCategories.includes(category.id)}
                  loadingItems={loadingItems[category.id]}
                  toggleCategory={toggleCategory}
                  updateItemOrder={updateItemOrder}
                  onEdit={onEditItem}
                  onDelete={confirmDelete}
                  query={query}
                />
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      </Box>

      {/* Alert dialog box for deleting confirmation */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Item
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default MenuOverview;
