import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Switch,
  Text,
  Textarea,
  useColorMode,
  useColorModeValue,
  VStack,
  Wrap,
  WrapItem,
  List,
  ListItem,
  InputRightElement,
  CloseButton,
  useOutsideClick,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { GrUpdate } from "react-icons/gr";
import API, { getMediaURL } from "../../context/API";

const SideBox = ({
  isOpen,
  onClose,
  item,
  onSave,
  imageFile,
  imageInputRef,
  handleImageChange,
  onCustomizationClick,
}) => {
  const [currentItem, setCurrentItem] = useState(item || {});
  const [customizations, setCustomizations] = useState(
     []
  );
  const [categories, setCategories] = useState([]); // Track added categories
  const [queryCustomizations, setQueryCustomizations] = useState(""); // For customization search
  const [queryCategories, setQueryCategories] = useState(""); // For category search
  const [filteredCustomizations, setFilteredCustomizations] = useState([]); // For filtered customization items
  const [filteredCategories, setFilteredCategories] = useState([]); // For filtered category items
  const [allCustomizations, setAllCustomizations] = useState([]);

  const [showSuggestionsCustomizations, setShowSuggestionsCustomizations] =
    useState(false);
  const [showSuggestionsCategories, setShowSuggestionsCategories] =
    useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]); // Track all available categories
  const [oldCategoryIds, setOldCategoryIds] = useState([]);

  const suggestionsBoxRef = useRef(null);
  const categoriesBoxRef = useRef(null);

  const toast = useToast(); // Initialize toast

  useEffect(() => {
    setCurrentItem(item || {});

    if (item && item.id) {
      fetchItemCustomizations(item.id);
      fetchItemCategories(item.id);
    } else {
      setCustomizations([]);
      setCategories([]);
    }

    fetchCustomizations(); // Fetch all customizations for suggestions
    fetchCategories(); // Fetch all categories for suggestions
  }, [item]);

  const fetchItemCustomizations = async (itemId) => {
    try {
      const response = await API.get(`/items/${itemId}/customizations`);
      if (response.data.success) {
        const customizationsData = response.data.data.customizations.map(
          (item) => {
            const customization = item.customization;
            return {
              ...customization,
              sequence_number: item.sequence_number,
            };
          }
        );

        console.log(customizationsData);
        // Sort customizations by sequence_number
        const sortedCustomizations = customizationsData.sort(
          (a, b) => parseInt(a.sequence_number) - parseInt(b.sequence_number)
        );
        setCustomizations(sortedCustomizations);
      } else {
        toast({
          title: "Failed to fetch item customizations.",
          description: "Unable to load customizations.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching item customizations:", error);
      toast({
        title: "Error fetching item customizations.",
        description: "Unable to load customizations.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchItemCategories = async (itemId) => {
    try {
      const response = await API.get(`/items/${itemId}/categories`);
      if (response.data.success) {
        const categoriesData = response.data.data.category_names;
        setCategories(categoriesData);
      } else {
        toast({
          title: "Failed to fetch item categories.",
          description: "Unable to load categories.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching item categories:", error);
      toast({
        title: "Error fetching item categories.",
        description: "Unable to load categories.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (item) {
      const categoryIds = item.category_names
        ? item.category_names.map((cat) => cat.id)
        : [];
      setOldCategoryIds(categoryIds);
    } else {
      setOldCategoryIds([]);
    }
  }, [item]);

  const { colorMode } = useColorMode();
  const optionsBorderColor = useColorModeValue("gray.300", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.700");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prev) => ({ ...prev, [name]: value }));
  };

  // Handle toggle for sold out status
  const handleSoldOutToggle = () => {
    setCurrentItem((prev) => ({ ...prev, sold_out: !prev.sold_out }));
  };

  // Fetch customizations from the API
  const fetchCustomizations = async () => {
    try {
      const response = await API.get("/customizations");
      setAllCustomizations(response.data.data); // Store full list
      setFilteredCustomizations(response.data.data); // Set filtered items to the API response
    } catch (error) {
      console.error("Error fetching customizations:", error);
    }
  };

  // Fetch categories from the API
  const fetchCategories = async () => {
    try {
      const response = await API.get("/categories");
      setCategoryOptions(response.data.data); // Store categories data
      setFilteredCategories(response.data.data); // Initialize with all categories
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  // Handle search input for customizations
  const handleSearchCustomizations = (value) => {
    setQueryCustomizations(value);
    if (value) {
      setFilteredCustomizations(
        allCustomizations.filter((item) =>
          item.customization.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredCustomizations(allCustomizations);
    }
  };

  // Handle search input for categories
  const handleSearchCategories = (value) => {
    setQueryCategories(value);
    if (value) {
      setFilteredCategories(
        categoryOptions.filter((item) =>
          item.category.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredCategories(categoryOptions); // Reset to all categories if input is cleared
    }
  };

  const updateCustomizationSequenceNumbers = (customizations) => {
    return customizations.map((c, index) => ({
      ...c,
      sequence_number: index.toString(),
    }));
  };

  // Handle adding a customization
  const handleOptionSelectCustomization = (selectedItem) => {
    const alreadyExists = customizations.some(
      (customization) => customization.id === selectedItem.id
    );

    if (!alreadyExists) {
      setCustomizations((prevCustomizations) => {
        const newCustomizations = [...prevCustomizations, selectedItem];
        return updateCustomizationSequenceNumbers(newCustomizations);
      });
    }
    setShowSuggestionsCustomizations(false);
    setQueryCustomizations("");
  };

  // Handle selecting a category
  const handleOptionSelectCategory = (selectedItem) => {
    const alreadyExists = categories.some((c) => c.id === selectedItem.id);
    if (!alreadyExists) {
      const newCategory = { ...selectedItem, isNew: true };
      setCategories((prev) => [...prev, newCategory]);
    }
    setShowSuggestionsCategories(false);
    setQueryCategories("");
  };

  const handleRemoveCustomization = (id) => {
    setCustomizations((prevCustomizations) => {
      const updatedCustomizations = prevCustomizations.filter(
        (c) => c.id !== id
      );
      return updateCustomizationSequenceNumbers(updatedCustomizations);
    });
  };

  const handleRemoveCategory = (id) => {
    const updatedCategories = categories.filter((c) => c.id !== id);
    setCategories(updatedCategories);
  };

  const handleSave = () => {
    console.log("Current item data in SideBox:", currentItem);

    const isCreating = !item?.id;

    if (isCreating) {
      const missingFields = [];

      if (!currentItem.item || currentItem.item.trim() === "") {
        missingFields.push("Item Name");
      }
      if (!currentItem.price || isNaN(currentItem.price)) {
        missingFields.push("Price");
      }
      // if

      if (missingFields.length > 0) {
        const formattedMissingFields = missingFields
          .map((field) => `• ${field}`)
          .join("\n");

        toast({
          title: "Missing Required Fields",
          description: `Please fill out the following required fields:\n${formattedMissingFields}`,
          status: "error",
          duration: 7000, // Increased duration for readability
          isClosable: true,
          whiteSpace: "pre-line", // Preserve line breaks
        });
        return; // Abort save operation
      }
    }

    // Prepare the data to be saved
    const formattedItemData = {
      ...currentItem,
      price: parseFloat(currentItem.price), // Convert price to number
      sequence_number: currentItem.sequence_number,
      customizations: customizations.map((c) => ({
        customization: c.id.toString(),
        sequence_number: c.sequence_number,
      })),
      category_names: categories.map((category) => {
        if (
          typeof category === "object" &&
          category !== null &&
          "id" in category
        ) {
          return category.id.toString();
        }
        return category.toString();
      }),
    };

    // Automatically set 'name' equal to 'item' for POST requests
    if (isCreating) {
      delete formattedItemData.sequence_number;
      formattedItemData.name = formattedItemData.item;
    }

    const newCategoryIds = categories.map((cat) => cat.id);

    const affectedCategories = [
      ...new Set([...newCategoryIds, ...oldCategoryIds]),
    ];

    console.log("Formatted data to save:", formattedItemData);

    onSave(formattedItemData, affectedCategories); // Call onSave passed from Menu.js
  };

  const handleClearSearchCustomizations = () => {
    setQueryCustomizations("");
    setShowSuggestionsCustomizations(false);
  };

  const handleClearSearchCategories = () => {
    setQueryCategories("");
    setShowSuggestionsCategories(false);
  };

  useOutsideClick({
    ref: suggestionsBoxRef,
    handler: () => setShowSuggestionsCustomizations(false),
  });

  useOutsideClick({
    ref: categoriesBoxRef,
    handler: () => setShowSuggestionsCategories(false),
  });

  return (
    <Box
      position="sticky"
      top="20"
      maxHeight={`calc(100vh - var(--chakra-space-40))`}
      overflowY="auto"
      w="400px"
      p={4}
      pt={0}
      ml={4}
      bg={colorMode === "light" ? "white" : "gray.800"}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="8"
    >
      <VStack spacing={4} align="flex-start">
        <Box
          position="sticky"
          top="0"
          zIndex="1"
          bg={colorMode === "light" ? "white" : "gray.800"}
          w="100%"
          pt={2}
        >
          <Flex justifyContent={"space-between"} align={"center"} mb={5}>
            <Flex justify={"flex-start"} align={"center"}>
              <IconButton
                onClick={onClose}
                icon={<CloseIcon />}
                variant="ghost"
                borderRadius={"100%"}
              />
              <Heading fontSize={"lg"} ml={2}>
                {currentItem && currentItem.id ? "Edit Item" : "Add Item"}
              </Heading>
            </Flex>

            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              onClick={handleSave}
            >
              Save
            </Button>
          </Flex>
          <Divider my={"4"} />
        </Box>
      </VStack>
      <Heading fontSize={"lg"} mb={"4"}>
        About {currentItem.item}
      </Heading>
      <VStack spacing={4} align="stretch">
        <FormControl id="item" mb="4" isRequired>
          <Heading fontSize={"md"} my={1}>
            Item Name
          </Heading>
          <Input
            name="item"
            placeholder="Enter item name"
            value={currentItem.item || ""}
            onChange={handleInputChange}
            variant={"filled"}
          />
        </FormControl>
        <FormControl id="description" mb="4">
          <Heading fontSize={"md"} my={1}>
            Description
          </Heading>
          <Textarea
            name="description"
            placeholder="Enter description"
            value={currentItem.description || ""}
            onChange={handleInputChange}
            variant={"filled"}
          />
        </FormControl>
        <FormControl id="price" mb="4" isRequired>
          <Heading fontSize={"md"} my={1}>
            Price
          </Heading>
          <Input
            type="number"
            name="price"
            placeholder="Enter price"
            value={currentItem.price || ""}
            onChange={handleInputChange}
            variant={"filled"}
          />
        </FormControl>

        <FormControl id="sold_out" mb="4">
          <Heading fontSize={"md"} my={1}>
            Mark item as sold out
          </Heading>
          <Switch
            isChecked={currentItem.sold_out}
            onChange={handleSoldOutToggle}
          />
        </FormControl>

        <FormControl mb="4">
          <Heading fontSize={"md"} mb={2}>
            Customizations
          </Heading>
          <Box position="relative" ref={suggestionsBoxRef}>
            <InputGroup>
              <Input
                placeholder="Search customizations..."
                value={queryCustomizations}
                onChange={(e) => handleSearchCustomizations(e.target.value)}
                onFocus={() => setShowSuggestionsCustomizations(true)}
                variant="filled"
                borderRadius="full"
                mb={4}
              />
              {queryCustomizations && (
                <InputRightElement>
                  <CloseButton onClick={handleClearSearchCustomizations} />
                </InputRightElement>
              )}
            </InputGroup>

            {/* Customization Suggestions */}
            {showSuggestionsCustomizations &&
              filteredCustomizations.length > 0 && (
                <Box
                  position="absolute"
                  bg={colorMode === "light" ? "white" : "gray.700"}
                  border="1px solid"
                  borderColor={optionsBorderColor}
                  borderRadius="md"
                  mt={2}
                  width="full"
                  zIndex="1"
                  maxHeight="150px"
                  overflowY="auto"
                >
                  <List spacing={1}>
                    {filteredCustomizations.map((item) => (
                      <ListItem
                        key={item.id}
                        p={2}
                        cursor="pointer"
                        _hover={{
                          bg: colorMode === "light" ? "gray.200" : "gray.600",
                        }}
                        onClick={() => handleOptionSelectCustomization(item)}
                      >
                        {item.customization}
                        {customizations.some((c) => c.id === item.id) && (
                          <CheckIcon color="green.500" ml={2} />
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
          </Box>
          {customizations.length === 0 ? (
            <Text color="gray.500">No modifier groups selected.</Text>
          ) : (
            <Wrap spacing={2} align="flex-start">
              {customizations.map((customization) => (
                <WrapItem key={customization.id}>
                  <Badge
                    display="flex"
                    alignItems="center"
                    p={2}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="green"
                    cursor={"pointer"}
                    onClick={() => onCustomizationClick(customization.id)} // Handle click
                  >
                    {customization.customization || "unnamed customization"}
                    <IconButton
                      icon={<CloseIcon />}
                      size="xs"
                      ml={2}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent badge click when removing
                        handleRemoveCustomization(customization.id);
                      }}
                      variant="unstyled"
                      aria-label="Remove Customization"
                      _hover={{ color: "red.500" }}
                    />
                  </Badge>
                </WrapItem>
              ))}
            </Wrap>
          )}
        </FormControl>

        {/* Categories Search and Badge Section */}
        <FormControl mb="4">
          <Heading fontSize={"md"} mb={2}>
            Categories
          </Heading>
          <Box position="relative" ref={categoriesBoxRef}>
            <InputGroup>
              <Input
                placeholder="Search categories..."
                value={queryCategories}
                onChange={(e) => handleSearchCategories(e.target.value)}
                onFocus={() => setShowSuggestionsCategories(true)}
                variant="filled"
                borderRadius="full"
                mb={4}
              />
              {queryCategories && (
                <InputRightElement>
                  <CloseButton onClick={handleClearSearchCategories} />
                </InputRightElement>
              )}
            </InputGroup>

            {/* Category Suggestions */}
            {showSuggestionsCategories && filteredCategories.length > 0 && (
              <Box
                position="absolute"
                bg={colorMode === "light" ? "white" : "gray.700"}
                border="1px solid"
                borderColor={optionsBorderColor}
                borderRadius="md"
                mt={2}
                width="full"
                zIndex="1"
                maxHeight="150px"
                overflowY="auto"
              >
                <List spacing={1}>
                  {filteredCategories.map((category) => (
                    <ListItem
                      key={category.id}
                      p={2}
                      cursor="pointer"
                      _hover={{
                        bg: colorMode === "light" ? "gray.200" : "gray.600",
                      }}
                      onClick={() => handleOptionSelectCategory(category)}
                    >
                      {category.category}
                      {categories.some((c) => c.id === category.id) && (
                        <CheckIcon color="green.500" ml={2} />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
          <Wrap spacing={2} align="flex-start">
            {categories.map((category) => (
              <WrapItem key={category.id}>
                <Badge
                  display="flex"
                  alignItems="center"
                  p={2}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="blue"
                >
                  {category.category}
                  <IconButton
                    icon={<CloseIcon />}
                    size="xs"
                    ml={2}
                    onClick={() => handleRemoveCategory(category.id)}
                    variant="unstyled"
                    aria-label="Remove Category"
                    _hover={{ color: "red.500" }}
                  />
                </Badge>
              </WrapItem>
            ))}
          </Wrap>
        </FormControl>

        <FormControl id="picture" mb="4" isRequired>
          <FormLabel>Photo</FormLabel>
          <Box position="relative">
            <Image
              src={
                imageFile
                  ? URL.createObjectURL(imageFile)
                  : currentItem.picture
                  ? getMediaURL(currentItem.picture)
                  : "https://via.placeholder.com/320x240"
              }
              alt="Item Image"
              width="100%"
              height="300"
              objectFit="cover"
              borderRadius="md"
              fallbackSrc="https://via.placeholder.com/320x240"
            />
            <Button
              onClick={() => imageInputRef.current.click()}
              position="absolute"
              leftIcon={
                currentItem && currentItem.id ? <GrUpdate /> : <AddIcon />
              }
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              top="2"
              right="2"
              size={"sm"}
              borderRadius={"full"}
            >
              {currentItem && currentItem.id ? "Update image" : "Add image"}
            </Button>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={imageInputRef}
              style={{ display: "none" }}
            />
          </Box>
          <Text fontSize="sm" color="gray.500" mt={2}>
            File requirement: JPG, PNG, GIF, or WEBP up to 10 MB. Minimum pixels
            required: 320 for width and height.
          </Text>
        </FormControl>
      </VStack>
    </Box>
  );
};

export default SideBox;
