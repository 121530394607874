// Customers.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Heading,
  IconButton,
  SimpleGrid,
  Grid,
  GridItem,
  Avatar,
} from "@chakra-ui/react";
import { FiArrowLeft, FiUsers, FiTrendingUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

/**
 * InfoTile Component
 * Displays individual metrics such as Total Customers, New Customers, etc.
 *
 * @param {object} props - Component props.
 * @param {string} props.title - Title of the metric.
 * @param {string} props.subTitle - Subtitle or description of the metric.
 * @param {string|number} props.value - Value of the metric.
 * @param {React.Component} props.icon - Icon representing the metric.
 * @param {string} props.iconColor - Color of the icon.
 * @param {object} props.chartData - Data for the chart.
 * @returns JSX Element
 */
const InfoTile = ({ title, subTitle, value, icon, iconColor, chartData }) => {
    // Define color constants
    const tileBg = useColorModeValue("gray.100", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");
    const subTextColor = useColorModeValue("gray.600", "gray.300");
  
    // Chart configuration
    const data = {
      labels: chartData ? chartData.labels : [],
      datasets: [
        {
          data: chartData ? chartData.data : [],
          backgroundColor: chartData ? chartData.backgroundColor : [],
          hoverBackgroundColor: chartData ? chartData.hoverBackgroundColor : [],
          borderWidth: 0,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '65%', // Adjust cutout percentage for doughnut thickness
      plugins: {
        legend: {
          display: false, // Hide legend
        },
        tooltip: {
          enabled: true, // Enable tooltips
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          titleColor: '#fff',
          bodyColor: '#fff',
          bodyFont: {
            size: 14,
          },
          padding: 10,
          cornerRadius: 4,
          callbacks: {
            label: function (context) {
              const label = context.label || '';
              const value = context.formattedValue || '';
              const total = context.dataset.data.reduce((a, b) => a + b, 0);
              const percentage = ((context.raw / total) * 100).toFixed(2);
              return `${label}: ${value} (${percentage}%)`;
            },
          },
        },
      },
      elements: {
        arc: {
          borderWidth: 0, // Remove segment borders
        },
      },
    };
  
    return (
      <Box
        bg={tileBg}
        borderRadius="md"
        p={4}
        w="100%"
        h="300px" // Fixed height for consistent tile sizes
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* Top Section: Icon and Text */}
        <Box>
          <HStack align="start" mb={2}>
            <Icon as={icon} w={8} h={8} color={iconColor} />
            <Box>
              <Text fontSize="lg" fontWeight="bold" color={textColor}>
                {title}
              </Text>
              <Text fontSize="sm" color={subTextColor} noOfLines={3}>
                {subTitle}
              </Text>
            </Box>
          </HStack>
          {/* Remove the value from here if not needed */}
        </Box>
        {/* Bottom Section: Chart with Value Inside */}
        {chartData && (
          <Box
            position="relative"
            width="100%"
            height="150px" // Adjust height as needed
            mt={2}
          >
            <Doughnut data={data} options={options} />
            {/* Value Text Positioned at the Center of the Chart */}
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              textAlign="center"
              pointerEvents="none" // Allow hover events to pass through to the chart
            >
              <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                {value}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  
/**
 * CustomersRankingGrid Component
 * Displays the top 5 customers in a grid layout.
 *
 * @param {object} props - Component props.
 * @param {Array} props.data - Array of top customer objects.
 * @returns JSX Element
 */
const CustomersRankingGrid = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const headerBg = useColorModeValue("gray.100", "gray.700");
  const rowBorderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4} p={2} borderRadius="md">
      {/* Header Row */}
      <GridItem colSpan={1}>
        <Text fontWeight="bold" color={textColor}>
          Customer
        </Text>
      </GridItem>
      <GridItem>
        <Text fontWeight="bold" color={textColor} textAlign="right">
          Visits
        </Text>
      </GridItem>
      <GridItem>
        <Text fontWeight="bold" color={textColor} textAlign="right">
          Total Spend ($)
        </Text>
      </GridItem>

      {/* Data Rows */}
      {data.slice(0, 5).map((customer, index) => (
        <React.Fragment key={customer.id}>
          {/* Customer Name with Avatar */}
          <GridItem>
            <HStack spacing={3}>
              <Avatar
                name={customer.name}
                size="sm"
                bg={rowBorderColor}
              />
              <Text color={textColor}>{customer.name}</Text>
            </HStack>
          </GridItem>
          {/* Visits */}
          <GridItem>
            <Text color={textColor} textAlign="right">
              {customer.visits}
            </Text>
          </GridItem>
          {/* Total Spend */}
          <GridItem>
            <Text color={textColor} textAlign="right">
              ${customer.totalSpend.toLocaleString()}
            </Text>
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
  );
};

/**
 * CustomerTrendsChart Component
 * Renders a line chart showing customer visits over time.
 *
 * @param {object} props - Component props.
 * @param {Array} props.data - Array of data points for the chart.
 * @returns JSX Element
 */
const CustomerTrendsChart = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const lineColor = useColorModeValue("blue.500", "lightgray"); // Light grey for dark mode
  const gridColor = useColorModeValue("gray.200", "gray.600");
  const tooltipBgColor = useColorModeValue("white", "gray.700");
  const tooltipTextColor = useColorModeValue("gray.800", "white");

  // Prepare chart data
  const chartData = useMemo(
    () => ({
      labels: data.map((point) => point.label),
      datasets: [
        {
          label: "Number of Customers",
          data: data.map((point) => point.value),
          fill: false,
          backgroundColor: lineColor,
          borderColor: lineColor,
          tension: 0.1,
          pointBackgroundColor: lineColor,
          pointBorderColor: lineColor,
          pointHoverBackgroundColor: lineColor,
          pointHoverBorderColor: lineColor,
        },
      ],
    }),
    [data, lineColor]
  );

  // Chart options
  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: textColor,
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: tooltipBgColor,
          titleColor: tooltipTextColor,
          bodyColor: tooltipTextColor,
          borderColor: lineColor,
          borderWidth: 1,
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColor,
            maxRotation: 0, // Keep labels horizontal
            minRotation: 0,
            font: {
              size: 12,
            },
            callback: function (value) {
              const label = this.getLabelForValue(value);
              return label.length > 10 ? label.substring(0, 10) + "..." : label;
            },
          },
          grid: {
            display: false, // Remove vertical grid lines
            color: gridColor,
          },
        },
        y: {
          ticks: {
            color: textColor,
            font: {
              size: 12,
            },
            beginAtZero: true,
          },
          grid: {
            display: false, // Remove horizontal grid lines
            color: gridColor,
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 4,
        },
      },
    }),
    [textColor, tooltipBgColor, tooltipTextColor, lineColor, gridColor]
  );

  return (
    <Box height="400px" w="100%">
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

/**
 * RecentCustomersTable Component
 * Displays a table of recent customer visits.
 *
 * @param {object} props - Component props.
 * @param {Array} props.data - Array of recent customer visit objects.
 * @returns JSX Element
 */
const RecentCustomersTable = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const gridHeaderBg = useColorModeValue("gray.100", "gray.700");
  const gridRowBorderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box w="100%" mt={4}>
      <Text fontSize="lg" fontWeight="bold" mb={2} color={textColor}>
        Recent Customer Visits
      </Text>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={4}
        p={2}
        borderRadius="md"
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
      >
        {/* Header Row */}
        <GridItem colSpan={1}>
          <Text fontWeight="bold" color={textColor}>
            Customer
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Date
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Time
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Amount Spent ($)
          </Text>
        </GridItem>

        {/* Data Rows */}
        {data.map((visit) => (
          <React.Fragment key={visit.id}>
            {/* Customer Name with Avatar */}
            <GridItem>
              <HStack spacing={3}>
                <Avatar
                  name={visit.name}
                  size="sm"
                  bg={gridRowBorderColor}
                />
                <Text color={textColor}>{visit.name}</Text>
              </HStack>
            </GridItem>
            {/* Date */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                {visit.date}
              </Text>
            </GridItem>
            {/* Time */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                {visit.time}
              </Text>
            </GridItem>
            {/* Amount Spent */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                ${visit.amountSpent.toLocaleString()}
              </Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

/**
 * Customers Component
 * Displays overview and analytics of customers visiting the restaurant.
 *
 * @returns JSX Element
 */
const Customers = () => {
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Define color constants using useColorModeValue
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const boxBgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const iconColor = useColorModeValue("black", "lightgrey"); // Black for light mode, light grey for dark mode
  const chartColor1 = useColorModeValue("#666666", "#c2c2c2"); 
  const chartColor2 = useColorModeValue("#2e2e2e", "#7d7d7d"); 
  const chartColorHover1 = useColorModeValue("#c2c2c2", "#e0e0e0"); 
  const chartColorHover2 = useColorModeValue("#e0e0e0", "#e0e0e0"); 

  // Fetch customer data (Replace with actual API call)
  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        // Simulate API call delay
        await new Promise((resolve) => setTimeout(resolve, 1500));

        // Sample data (Replace with actual data)
        const sampleData = {
          metrics: {
            totalCustomers: 500,
            newCustomers: 150,
            repeatCustomers: 350,
            averageSpend: 50, // Added
            averageVisits: 3, // Added
          },
          topCustomers: [
            {
              id: 1,
              name: "John Doe",
              image: "https://via.placeholder.com/40",
              visits: 20,
              totalSpend: 500,
            },
            {
              id: 2,
              name: "Jane Smith",
              image: "https://via.placeholder.com/40",
              visits: 18,
              totalSpend: 450,
            },
            {
              id: 3,
              name: "Mike Johnson",
              image: "https://via.placeholder.com/40",
              visits: 15,
              totalSpend: 400,
            },
            {
              id: 4,
              name: "Emily Davis",
              image: "https://via.placeholder.com/40",
              visits: 12,
              totalSpend: 350,
            },
            {
              id: 5,
              name: "David Wilson",
              image: "https://via.placeholder.com/40",
              visits: 10,
              totalSpend: 300,
            },
          ],
          customerTrends: [
            { label: "Jan", value: 50 },
            { label: "Feb", value: 60 },
            { label: "Mar", value: 70 },
            { label: "Apr", value: 80 },
            { label: "May", value: 90 },
            { label: "Jun", value: 100 },
            { label: "Jul", value: 110 },
            { label: "Aug", value: 120 },
            { label: "Sep", value: 130 },
            { label: "Oct", value: 140 },
            { label: "Nov", value: 150 },
            { label: "Dec", value: 160 },
          ],
          recentVisits: [
            {
              id: 1,
              name: "John Doe",
              image: "https://via.placeholder.com/40",
              date: "2024-09-15",
              time: "18:30",
              amountSpent: 50,
            },
            {
              id: 2,
              name: "Jane Smith",
              image: "https://via.placeholder.com/40",
              date: "2024-09-14",
              time: "19:00",
              amountSpent: 45,
            },
            {
              id: 3,
              name: "Mike Johnson",
              image: "https://via.placeholder.com/40",
              date: "2024-09-13",
              time: "20:15",
              amountSpent: 40,
            },
            {
              id: 4,
              name: "Emily Davis",
              image: "https://via.placeholder.com/40",
              date: "2024-09-12",
              time: "17:45",
              amountSpent: 35,
            },
            {
              id: 5,
              name: "David Wilson",
              image: "https://via.placeholder.com/40",
              date: "2024-09-11",
              time: "18:00",
              amountSpent: 30,
            },
          ],
        };

        setCustomerData(sampleData);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setCustomerData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  // Prepare data for Customer Trends Chart
  const customerTrendsData = useMemo(() => {
    if (!customerData) return [];
    return customerData.customerTrends;
  }, [customerData]);

  return (
    <Box p={5} color={textColor}>
      {loading ? (
        <Flex justify="center" align="center" minH="80vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* Header with Back Button */}
          <Flex align="center">
            <IconButton
              icon={<FiArrowLeft />}
              variant="ghost"
              color={iconColor}
              onClick={() => navigate(-1)}
              aria-label="Go Back"
              mr={4}
              size="lg"
            />
            <HStack spacing={2}>
              <Icon as={FiUsers} w={6} h={6} color={iconColor} />
              <Heading size="lg">Customers</Heading>
            </HStack>
          </Flex>

          {/* Overview Tiles */}
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "repeat(auto-fit, minmax(200px, 1fr))",
            }}
            gap={6}
          >
            <InfoTile
              title="Total Customers"
              subTitle="Total number of customers"
              value={customerData.metrics.totalCustomers}
              icon={FiUsers}
              chartData={{
                labels: ["Total Customers"],
                data: [1],
                backgroundColor: [chartColor1],
                hoverBackgroundColor: [chartColorHover1],
              }}
            />
            <InfoTile
              title="New Customers"
              subTitle="Customers who visited for the first time"
              value={customerData.metrics.newCustomers}
              icon={FiUsers}
              chartData={{
                labels: ["New", "Others"],
                data: [
                  customerData.metrics.newCustomers,
                  customerData.metrics.totalCustomers -
                    customerData.metrics.newCustomers,
                ],
                backgroundColor: [chartColor1, chartColor2], // Blue and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Blue and Darker Gray
              }}
            />
            <InfoTile
              title="Repeat Customers"
              subTitle="Customers who have visited before"
              value={customerData.metrics.repeatCustomers}
              icon={FiUsers}
              chartData={{
                labels: ["New", "Others"],
                data: [
                  customerData.metrics.newCustomers,
                  customerData.metrics.totalCustomers -
                    customerData.metrics.newCustomers,
                ],
                backgroundColor: [chartColor1, chartColor2], // Blue and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Blue and Darker Gray
              }}
            />
            <InfoTile
              title="Average Spend per Customer"
              subTitle="Average amount spent per customer"
              value={`$${customerData.metrics.averageSpend}`}
              icon={FiTrendingUp}
              chartData={{
                labels: ["Average", "Others"],
                data: [customerData.metrics.averageSpend], // Example data
                backgroundColor: [chartColor1, chartColor2], // Orange and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Orange and Darker Gray
              }}
            />
            <InfoTile
              title="Average Visits per Customer"
              subTitle="Average number of visits per customer"
              value={customerData.metrics.averageVisits}
              icon={FiTrendingUp}
              chartData={{
                labels: ["Average", "Others"],
                data: [parseInt(customerData.metrics.averageVisits), 10], // Example data
                backgroundColor: [chartColor1, chartColor2], // Pink and Gray
                hoverBackgroundColor: [chartColorHover1, chartColorHover2], // Light Pink and Darker Gray
              }}
            />
          </Grid>

          {/* Top Customers Ranking Grid */}
          <Box p={4} borderRadius="md" border="1px" borderColor={borderColor}>
            <Heading size="md" mb={4}>
              Top 5 Customers
            </Heading>
            <CustomersRankingGrid data={customerData.topCustomers} />
          </Box>

          {/* Customer Trends Line Chart */}
          <Box p={4} borderRadius="md" border="1px" borderColor={borderColor}>
            <Heading size="md" mb={4}>
              Customer Visits Over Time
            </Heading>
            <CustomerTrendsChart data={customerTrendsData} />
          </Box>

          {/* Recent Customers Table */}
          <Box bg={boxBgColor} p={4} borderRadius="md" boxShadow="md">
            <RecentCustomersTable data={customerData.recentVisits} />
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default Customers;
