// MerchantAnalytics.jsx
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Text,
  Icon,
  Flex,
  Badge,
  Spinner,
  VStack,
  HStack,
  Button,
  useColorModeValue,
  SimpleGrid,
  Avatar,
  Tooltip,
  Stack,
  Divider,
  SlideFade,
  Heading,
} from "@chakra-ui/react";
import {
  FiTrendingUp,
  FiTrendingDown,
  FiPieChart,
  FiStar,
  FiUserPlus,
  FiAlertCircle,
  FiArrowLeft,
  FiSmartphone,
  FiUsers,
} from 'react-icons/fi';
import { Line, Pie, Bar } from 'react-chartjs-2';
import "../../theme/Layout/ChartConfig";

import axios from 'axios';
import { AiOutlineMobile } from "react-icons/ai";
import API from '../../context/API';
import SalesDetailedView from './SalesDetailedView';
import { useNavigate } from 'react-router-dom';

// Utility function to format "time ago"
const formatTimeAgo = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);
  
  if (interval >= 1) return `${interval} year${interval > 1 ? 's' : ''} ago`;
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) return `${interval} month${interval > 1 ? 's' : ''} ago`;
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return `${interval} day${interval > 1 ? 's' : ''} ago`;
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return `${interval} hour${interval > 1 ? 's' : ''} ago`;
  interval = Math.floor(seconds / 60);
  if (interval >= 1) return `${interval} minute${interval > 1 ? 's' : ''} ago`;
  return 'Just now';
};

const MerchantAnalytics = ({ role }) => {
  // State variables
  const navigate = useNavigate(); // Initialize useNavigate

  const [selectedTile, setSelectedTile] = useState(null);
  const [merchantData, setMerchantData] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [categorySalesData, setCategorySalesData] = useState(null);
  const [itemSalesData, setItemSalesData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [analyticsLoading, setAnalyticsLoading] = useState(true);
  
  const [lastUpdated, setLastUpdated] = useState(null);

  // Chakra UI color modes
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBg = useColorModeValue("gray.100", "gray.700");
  const tileHoverBg = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");

  // Fetch merchant data if role is 'merchant'
  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await API.get("merchant/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMerchantData(response.data.data);
        const now = Date.now();
        localStorage.setItem("lastUpdatedTimestamp", now.toString());
        setLastUpdated(formatTimeAgo(new Date(now)));
      } catch (error) {
        console.error("Error fetching merchant data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Determine user role;

    if (role === "merchant") {
      fetchMerchantData();
    } else {
      setLoading(false);
    }
  }, []);

    // Fetch analytics data
    useEffect(() => {
      const fetchAnalyticsData = async () => {
        try {
          const token = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${token}`,
          };
  
          const [salesResponse, categorySalesResponse, itemSalesResponse] = await Promise.all([
            API.get("/analytics/sales", { headers }),
            API.get("/analytics/category-sales", { headers }),
            API.get("/analytics/item-sales", { headers }),
          ]);
  
          setSalesData(salesResponse.data.data);
          setCategorySalesData(categorySalesResponse.data.data);
          setItemSalesData(itemSalesResponse.data.data);
  
        } catch (error) {
          console.error("Error fetching analytics data:", error);
        } finally {
          setAnalyticsLoading(false);
        }
      };
  
      fetchAnalyticsData();
    }, []);
  
    const tilesData = useMemo(() => {
      if (!salesData || !categorySalesData || !itemSalesData) return [];
  
      // Calculate total category revenue
      const totalCategoryRevenue = categorySalesData.categories.reduce(
        (sum, cat) => sum + parseFloat(cat.revenue),
        0
      );
  
      // Calculate total items sold
      const totalItemsSold = itemSalesData.items.reduce(
        (sum, item) => sum + item.timesSold,
        0
      );
  
      return [
        {
          id: "sales",
          title: "Sales",
          icon: FiTrendingUp,
          color: "grey.500",
          value: `$${parseFloat(salesData.netSales).toFixed(2)}`,
          trend: "up",
          trendValue: "0",
        },
        {
          id: "categorySales",
          title: "Category Sales",
          icon: FiPieChart,
          color: "grey.500",
          value: `$${totalCategoryRevenue.toFixed(2)}`,
          trend: "up",
          trendValue: "0",
        },
        {
          id: "itemSales",
          title: "Item Sales",
          icon: FiStar,
          color: "grey.500",
          value: `${totalItemsSold} times`,
          trend: "up",
          trendValue: "0",
        },
        {
          id: "customers",
          title: "Customers",
          icon: FiUsers,
          color: "grey.500",
          value: "0", // Update when customer data is available
          trend: "up",
          trendValue: "0",
        },
        {
          id: "disputes",
          title: "Disputes",
          icon: FiAlertCircle,
          color: "grey.500",
          value: "0", // Update when dispute data is available
          trend: "up",
          trendValue: "0",
        },
      ];
    }, [salesData, categorySalesData, itemSalesData]);
  

  // Chart options with consistent styling
  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColor,
          },
          grid: {
            color: "gray.200",
          },
        },
        y: {
          ticks: {
            color: textColor,
          },
          grid: {
            color: "gray.200",
          },
        },
      },
    }),
    [textColor]
  );

  // Reusable Analytics Tile Component
  const AnalyticsTile = React.memo(
    ({ title, icon, color, value, trend, trendValue, onClick }) => {
      const TrendIcon = trend === "up" ? FiTrendingUp : FiTrendingDown;
      const trendColor = trend === "up" ? "green.400" : "red.400";

      return (
        <Box
          bg={tileBg}
          borderRadius="md"
          p={6}
          cursor="pointer"
          _hover={{ bg: tileHoverBg }}
          transition="background 0.3s"
          boxShadow="md"
          onClick={onClick}
        >
          <Flex align="center" justifyContent="space-between">
            <Icon as={icon} w={8} h={8} color={color} />
            <Badge colorScheme={trend === "up" ? "green" : "red"}>
              <Flex align="center">
                <TrendIcon />
                <Text ml={1}>{trendValue}%</Text>
              </Flex>
            </Badge>
          </Flex>
          <Text fontSize="lg" color={textColor} mt={4}>
            {title}
          </Text>
          <Text fontSize="2xl" fontWeight="bold" color={textColor} mt={2}>
            {value}
          </Text>
        </Box>
      );
    }
  );

  // Reusable Detailed View Component
  const DetailedView = ({ title, chartType, data, options, onBack }) => {
    // Determine which chart component to render based on chartType
    const renderChart = () => {
      switch (chartType) {
        case "line":
          return <Line data={data} options={options} />;
        case "pie":
          return <Pie data={data} options={options} />;
        case "bar":
          return <Bar data={data} options={options} />;
        default:
          return null;
      }
    };

    return (
      <Box p={5} minH="100vh" color={textColor}>
        <VStack spacing={4} align="stretch">
          <HStack justifyContent="space-between">
            <Button
              leftIcon={<FiArrowLeft />}
              variant="outline"
              color={textColor}
              onClick={onBack}
              borderColor={bgColor}
              _hover={{ bg: "gray.200" }}
            >
              Back
            </Button>
            <Text fontSize="2xl" fontWeight="bold">
              {title} Details
            </Text>
            <Box></Box> {/* Placeholder for alignment */}
          </HStack>
          <Box
            bg={tileBg}
            p={4}
            borderRadius="md"
            height="400px"
            boxShadow="md"
          >
            {renderChart()}
          </Box>
        </VStack>
      </Box>
    );
  };

  // Loader Component
  const Loader = () => (
    <Flex justify="center" align="center" minH="100vh">
      <Spinner size="xl" />
    </Flex>
  );

  // Sessions and Devices Mapping Component
  const SessionsDevices = () => {
    if (!merchantData) return null;

    const { currentSessions, devices } = merchantData;

    return (
      <Box mt={10}>
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Active Sessions & Devices
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          {/* Current Sessions */}
          <Box bg={tileBg} p={6} borderRadius="md" boxShadow="md">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Current Sessions
            </Text>
            <VStack align="stretch" spacing={4}>
              {currentSessions.map((session) => (
                <Flex
                  key={session.id}
                  align="center"
                  p={3}
                  bg={bgColor}
                  borderRadius="full"
                >
                  <Avatar icon={<AiOutlineMobile />} mr={4} />
                  <Box>
                    <Text fontWeight="bold">{session.deviceName}</Text>
                    <Text fontSize="sm" color={subTextColor}>
                      OS: {session.deviceOs} | IP: {session.deviceIp}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Box>

          {/* Devices */}
          <Box bg={tileBg} p={6} borderRadius="md" boxShadow="md">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Devices
            </Text>
            <VStack align="stretch" spacing={4}>
              {devices.map((device) => (
                <Flex
                  key={device.id}
                  align="center"
                  p={3}
                  bg={bgColor}
                  borderRadius="full"
                >
                  <Avatar icon={<FiSmartphone />} mr={4} />
                  <Box>
                    <Text fontWeight="bold">{device.name}</Text>
                    <Text fontSize="sm" color={subTextColor}>
                      Type: {device.type.name} | ID: {device.identifier}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Box>
        </SimpleGrid>
      </Box>
    );
  };

  // Handle Tile Click
  const handleTileClick = (tile) => {
    if (tile.id === "sales") {
      navigate("/analytics/sales", { state: { data: salesData, itemRankingData: itemSalesData } });
    } else if (tile.id === "categorySales") {
      navigate("/analytics/category-sales", { state: { data: categorySalesData } });
    } else if (tile.id === "itemSales") {
      navigate("/analytics/item-sales", { state: { data: itemSalesData } }); 
    } else if (tile.id === "customers") {
      navigate("/analytics/customers"); 
    } else if (tile.id === "disputes") {
      navigate("/analytics/disputes"); 
    } else {
      setSelectedTile(tile);
    }
  };

  // Handle Back Button
  const handleBack = () => {
    setSelectedTile(null);
  };

  if (loading || analyticsLoading) {
    return <Loader />;
  }

  return (
    <SlideFade in={true} offsetY="20px">
      <Box p={5} color={textColor}>
        {!selectedTile ? (
          <>
            {/* Main Heading */}
            <Heading size="xl"  mb={4}>
              Analytics
            </Heading>

            {/* Welcome Subheading */}
            {merchantData && (
              <Box mb={6}>
                <Text fontSize="lg" color={textColor}>
                  Welcome,{" "}
                  <Text as="span" fontWeight="bold">
                    {merchantData.name}
                  </Text>
                  !
                </Text>
                <Text fontSize="sm" color={subTextColor}>
                  Here's a summary of your restaurant's performance.
                </Text>
              </Box>
            )}

            {/* Analytics Tiles */}
            <Grid
              templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
              gap={6}
            >
              {tilesData.map((tile) => (
                <AnalyticsTile
                  key={tile.id}
                  title={tile.title}
                  icon={tile.icon}
                  color={tile.color}
                  value={tile.value}
                  trend={tile.trend}
                  trendValue={tile.trendValue}
                  onClick={() => handleTileClick(tile)}
                />
              ))}
            </Grid>

            {/* Sessions and Devices Mapping */}
            <SessionsDevices />
          </>
        ) : (
          <DetailedView
            title={selectedTile.title}
            chartType={selectedTile.chartType}
            data={selectedTile.chartData}
            options={chartOptions}
            onBack={handleBack}
          />
        )}
      </Box>
    </SlideFade>
  );
};

export default MerchantAnalytics;
