import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Lottie from "react-lottie";
import lightOverlayAnimationData from "../assets/loginFormBgLight.json";
import darkOverlayAnimationData from "../assets/loginFormBgDark.json";
import lightAnimationData from "../assets/BgLight.json";
import darkAnimationData from "../assets/BgDark.json";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../context/LoadingContext";
import { ToggleColorModeButton } from "../theme/ToggleColorModeButton";
import { LoadingAnimation } from "./LoadingAnimation";

import axios from "axios";
import API from "../context/API";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { FaUsers, FaUserEdit, FaUser } from "react-icons/fa";

const LoginPage = ({ isLoggedIn, setIsLoggedIn, setRole }) => {
  const toast = useToast();
  const [emailOrUsername, setEmailUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setLoading } = useLoading();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { isLoading } = useLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [menuButtonWidth, setMenuButtonWidth] = useState("auto");
  const menuButtonRef = useRef(); // Ref for MenuButton

  const textColor = useColorModeValue("gray.800", "gray.100");

  const handleToggle = () => setIsOpen(!isOpen);

  // This useEffect will update the width of the menu list when the component is rendered
  useEffect(() => {
    if (menuButtonRef.current) {
      setMenuButtonWidth(`${menuButtonRef.current.offsetWidth}px`);
    }
  }, []);

  const submit = async (e, role) => {
    e.preventDefault();

    const user = {
      emailOrUsername: emailOrUsername.toLowerCase(),
      password: password,
      source: "web",
    };

    try {
      const { data } = await API.post(`${role}/login/`, user, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      if (data.status === 200) {
        showToast("Login Successful.", "You are now Signed in.", "success");
        setLoading(true);
        setTimeout(() => {
          setIsLoggedIn(true);
          setRole(role);
          localStorage.setItem("access_token", data.data.jwt);
          localStorage.setItem("role", role);
          localStorage.setItem("user", JSON.stringify(data.data[role]));
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data["access"]}`;
          window.location.href = "/";
          navigate("/dashboard");
          setLoading(false);
        }, 1000);
      } else {
        throw new Error(`Request failed with status ${data.status}`);
      }
    } catch (error) {
      showToast(
        "Unable To Login.",
        error.response ? error.response.data.detail : error.message,
        "error"
      );
    }
  };

  function showToast(title, description, status) {
    toast({
      title,
      description,
      status,
      duration: 1000,
      isClosable: true,
    });
  }

  const baseAnimationData =
    colorMode === "light" ? lightAnimationData : darkAnimationData;
  const overlayAnimationData =
    colorMode === "light"
      ? lightOverlayAnimationData
      : darkOverlayAnimationData;

  const baseOptions = {
    loop: false,
    autoplay: true,
    animationData: baseAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const overlayOptions = {
    loop: false,
    autoplay: true,
    animationData: overlayAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  return (
    <Box position="relative" height="100vh" width="100vw">
      {!isLoading ? (
        <>
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            overflow="hidden"
            pointerEvents={"none"}
          >
            <Box position="absolute" width="full" height="full">
              <Lottie options={baseOptions} />
            </Box>
            <Box position="absolute" width="full" height="full">
              <Lottie options={overlayOptions} />
            </Box>
          </Box>
          <ToggleColorModeButton
            width="24px"
            height="24px"
            iconButtonProps={{
              position: "absolute",
              top: "5",
              right: "5",
            }}
          />

          <VStack
            align="stretch"
            spacing={4}
            p={8}
            rounded="lg"
            maxWidth="400px"
            margin="auto"
            position="relative"
            top="50%"
            transform="translateY(-50%)"
            color={"black"}
          >
            <Text fontSize="2xl" fontWeight="bold" alignSelf="center">
              Login
            </Text>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="emailOrUsername"
                placeholder="Enter your email/username here"
                value={emailOrUsername}
                variant={"filled"}
                borderRadius={"full"}
                onChange={(e) => setEmailUsername(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                variant={"filled"}
                borderRadius={"full"}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <VStack spacing={4} align="stretch">
                <Button
                  colorScheme="green"
                  onClick={(e) => submit(e, "admin")}
                  w="full"
                  p={4}
                  borderRadius="full"
                  fontSize="md"
                  fontWeight="bold"
                  boxShadow="md"
                  _hover={{ boxShadow: "lg" }}
                >
                  Login as Admin
                </Button>
                <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
                  <MenuButton
                    as={Button}
                    colorScheme="green"
                    w="full"
                    p={4}
                    borderRadius="full"
                    fontSize="md"
                    fontWeight="bold"
                    boxShadow="md"
                    _hover={{ boxShadow: "lg" }}
                    onClick={handleToggle}
                    ref={menuButtonRef} // Attach the ref to MenuButton
                  >
                    <Flex justify="space-between" align="center" w="full">
                      <Box flex="1" textAlign="center">
                        Login as Merchant
                      </Box>
                      <Box>
                        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList
                    minW={menuButtonWidth} // Set width same as MenuButton
                    w={menuButtonWidth} // Ensure MenuList has the same width as MenuButton
                  >
                    <MenuItem
                      w={menuButtonWidth}
                      onClick={(e) => submit(e, "merchant")}
                      color={textColor}
                    >
                      <Icon as={FaUser} mr={4} />
                      {/* Add icon for Merchant */}
                      Merchant
                    </MenuItem>
                    <MenuItem
                      w={menuButtonWidth}
                      onClick={(e) => submit(e, "manager")}
                      color={textColor}
                    >
                      <Icon as={FaUserEdit} mr={4} />
                      {/* Add icon for Merchant */}
                      Merchant Manager
                    </MenuItem>
                    <MenuItem
                      w={menuButtonWidth}
                      onClick={(e) => submit(e, "staff")}
                      color={textColor}
                    >
                      <Icon as={FaUsers} mr={4} />
                      {/* Add icon for Merchant */}
                      Merchant Staff
                    </MenuItem>
                  </MenuList>
                </Menu>
              </VStack>
            </FormControl>
          </VStack>
        </>
      ) : (
        <>
          <LoadingAnimation size="large" />
        </>
      )}
    </Box>
  );
};

export default LoginPage;
