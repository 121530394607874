// CategorySales.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  SimpleGrid,
  useColorModeValue,
  Heading,
  Collapse,
  useDisclosure,
  SlideFade,
  IconButton,
  Image,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import {
  FiTrendingUp,
  FiTrendingDown,
  FiArrowLeft,
  FiShoppingBag,
} from "react-icons/fi";
import { Bar } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import Swiper modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Chart.js components
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import API, { getMediaURL } from "../../context/API";
Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// Reusable SalesSummary Component tailored for Category Sales
const CategorySalesSummary = ({ totalSales, weeklyChange, totalCategories }) => {
  // Determine the trend direction and color
  const isIncrease = weeklyChange >= 0;
  const trendIcon = isIncrease ? FiTrendingUp : FiTrendingDown;
  const trendColor = isIncrease ? "green.400" : "red.400";

  // Chakra UI color modes
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");

  return (
    <Box
      p={6}
      bg={useColorModeValue("gray.100", "gray.700")}
      borderRadius="md"
      boxShadow="md"
      mb={6}
    >
      <VStack align="start" spacing={0}>
        <Text fontSize="xl" color={textColor}>
          Total Category Sales
        </Text>
        <Text fontSize="sm" color={subTextColor} mb={"4"}>
          Comprehensive overview of sales across all categories.
        </Text>
        <HStack>
          <Text fontSize="4xl" fontWeight="bold" color={textColor}>
          ${parseFloat(totalSales).toFixed(2)}
          </Text>
          <Flex align="center">
            <Icon as={trendIcon} color={trendColor} w={5} h={5} />
            <Text ml={1} fontSize="md" color={trendColor} fontWeight="semibold">
              {weeklyChange === 0 ? "0%" : `${Math.abs(weeklyChange)}%`} {isIncrease ? "↑" : "↓"} this week
            </Text>
          </Flex>
        </HStack>
        <Text fontSize="md" color={subTextColor}>
          Total Categories: {totalCategories}
        </Text>
      </VStack>
    </Box>
  );
};

// New Reusable CategorySalesRankingCarousel Component with Ranking Numbers
const CategorySalesRankingCarousel = ({ data, rankingBy, onToggleRanking }) => {
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");
  const cardBg = useColorModeValue("gray.100", "gray.700");
  const cardTextColor = useColorModeValue("gray.800", "white");

  // Define gradient colors for rankings
  const getRankGradient = (rank) => {
    switch (rank) {
      case 1:
        return "linear(to-r, yellow.400, yellow.600)";
      case 2:
        return "linear(to-r, gray.300, gray.500)";
      case 3:
        return "linear(to-r, orange.400, orange.600)";
      default:
        return "linear(to-r, gray.400, gray.600)";
    }
  };

  return (
    <Box>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Box mr={"4"}>
          <Text fontSize="2xl" fontWeight="bold">
            Category Sales Ranking
          </Text>
          <Text fontSize="sm" color={subTextColor} mb={4}>
            Categories ranked by{" "}
            {rankingBy === "revenue" ? "revenue" : "units sold"}.
          </Text>
        </Box>
        <Button size="sm" onClick={onToggleRanking}>
          Rank by {rankingBy === "revenue" ? "Units Sold" : "Revenue"}
        </Button>
      </Flex>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesPerView={useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 })}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        style={{ padding: "20px 0" }}
      >
        {data.map((category, index) => (
          <SwiperSlide key={category.id}>
            <Box
              bg={cardBg}
              borderRadius="lg"
              p={6}
              textAlign="center"
              boxShadow="md"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* Ranking Number */}
              <Text
                fontSize="3xl"
                fontWeight="bold"
                bgGradient={getRankGradient(index + 1)}
                bgClip="text"
                mb={4}
              >
                {index + 1}
              </Text>
              {/* Category Image */}
              <Image
                src={"https://via.placeholder.com/100"}
                alt={category.category}
                borderRadius="full"
                boxSize="100px"
                objectFit="cover"
                mb={4}
                fallbackSrc="https://via.placeholder.com/100"
              />
              {/* Category Name */}
              <Text
                fontSize="lg"
                fontWeight="bold"
                color={cardTextColor}
                mb={2}
              >
                {category.category || " - "}
              </Text>
              {/* Revenue or Units Sold */}
              {rankingBy === "revenue" ? (
                <Text fontSize="sm" color={subTextColor}>
                  Revenue: ${parseFloat(category.revenue).toFixed(2)}
                </Text>
              ) : (
                <Text fontSize="sm" color={subTextColor}>
                  Units Sold: {category.itemsSold}
                </Text>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

// Category Sales Detailed View Component
const CategorySales = () => {
  const [categories, setCategories] = useState([]);
  const [loadingSales, setLoadingSales] = useState(true);
  const [totalSales, setTotalSales] = useState("0.00$");
  const [weeklyChange, setWeeklyChange] = useState(0);
  const [totalCategories, setTotalCategories] = useState(" - ");
  const [refreshing, setRefreshing] = useState(false);
  const [keyMetrics, setKeyMetrics] = useState([]);
  const [rankingBy, setRankingBy] = useState('revenue'); // 'revenue' or 'unitsSold'

  const location = useLocation();
  const { data } = location.state || {};

  const navigate = useNavigate(); // Initialize useNavigate

  // Chakra UI color modes
  const textColor = useColorModeValue("gray.800", "white");
  const barColor = useColorModeValue("black", "lightgrey");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const subTextColor = useColorModeValue("gray.600", "gray.400");

  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColor,
        },
        grid: {
          color: 'gray.200',
        },
      },
      y: {
        ticks: {
          color: textColor,
        },
        grid: {
          color: 'gray.200',
        },
      },
    },
  }), [textColor]);

  useEffect(() => {
    if (data && data.categories) {
      const categoriesData = data.categories;

      // Calculate total sales
      const totalRevenue = categoriesData.reduce((sum, category) => sum + parseFloat(category.revenue), 0);
      setTotalSales(totalRevenue);

      // Set total categories
      setTotalCategories(categoriesData.length);

      // Calculate average revenue per category
      const averageRevenue = totalRevenue / categoriesData.length || 0;

      // Find best category by revenue
      const bestCategoryByRevenue = categoriesData.reduce((prev, current) => (parseFloat(current.revenue) > parseFloat(prev.revenue) ? current : prev));

      // Find best category by units sold
      const bestCategoryByUnitsSold = categoriesData.reduce((prev, current) => (current.itemsSold > prev.itemsSold ? current : prev));

      // Set key metrics
      setKeyMetrics([
        {
          id: "totalSales",
          title: "Total Sales",
          subTitle: "Overall revenue from all categories",
          value: `$${totalRevenue.toFixed(2)}`,
          icon: FiTrendingUp,
          color: "green.500",
        },
        {
          id: "bestCategoryRevenue",
          title: "Best Category by Revenue",
          subTitle: bestCategoryByRevenue.category || "-",
          value: `$${parseFloat(bestCategoryByRevenue.revenue).toFixed(2)}`,
          icon: FiTrendingUp,
          color: "blue.500",
        },
        {
          id: "bestCategoryUnits",
          title: "Best Category by Units Sold",
          subTitle: bestCategoryByUnitsSold.category || "-",
          value: `${bestCategoryByUnitsSold.itemsSold} Units`,
          icon: FiTrendingUp,
          color: "orange.500",
        },
        {
          id: "averageSales",
          title: "Average Revenue per Category",
          subTitle: "Average sales per category",
          value: `$${averageRevenue.toFixed(2)}`,
          icon: FiTrendingUp,
          color: "purple.500",
        },
      ]);

      // Set categories for chart and ranking
      setCategories(categoriesData);
    }
    setLoadingSales(false);
  }, [data]);

    // Prepare data for the bar chart
    const chartData = useMemo(() => {
        if (!categories) return {};
    
        return {
          labels: categories.map(category => category.category || " - "),
          datasets: [
            {
              label: "Revenue ($)",
              data: categories.map(category => parseFloat(category.revenue)),
              backgroundColor: barColor,
              borderColor: barColor,
              borderWidth: 1,
            },
          ],
        };
      }, [categories, barColor]);
    
      // Toggle ranking between revenue and units sold
      const onToggleRanking = () => {
        setRankingBy(rankingBy === 'revenue' ? 'unitsSold' : 'revenue');
      };
    
      // Get sorted categories for ranking
      const sortedCategories = useMemo(() => {
        const sorted = [...categories];
        if (rankingBy === 'revenue') {
          sorted.sort((a, b) => parseFloat(b.revenue) - parseFloat(a.revenue));
        } else {
          sorted.sort((a, b) => b.itemsSold - a.itemsSold);
        }
        return sorted;
      }, [categories, rankingBy]);
    

  return (
    <Box p={5} color={textColor}>
      {loadingSales ? (
        <Flex justify="center" align="center" minH="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* Heading with Back Button */}
          <Flex justify="flex-start" align="center">
            <IconButton
              icon={<FiArrowLeft />}
              variant="outline"
              color={textColor}
              borderRadius={"full"}
              onClick={() => navigate(-1)} // Navigate back in history
              _hover={{ bg: "gray.200" }}
              aria-label="Go Back to Analytics Dashboard"
              mr={"4"}
              size="lg"
            />
            <VStack align="start" spacing={1}>
              <Heading size="xl" fontWeight="bold">
                Category Sales
              </Heading>
              <Text fontSize="md" color={subTextColor}>
                Analyze sales performance by category
              </Text>
            </VStack>
            {/* Placeholder to balance the flex layout */}
            <Box width="75px" />
          </Flex>

          {/* Sales Summary Header */}
          <CategorySalesSummary
            totalSales={totalSales}
            weeklyChange={weeklyChange}
            totalCategories={totalCategories}
          />

          {/* Sales Chart */}
          <Box p={4} borderRadius="md" height="400px" width={"full"}>
            <Bar
              data={chartData}
              options={{
                ...chartOptions,
                plugins: {
                  legend: {
                    labels: {
                      color: textColor,
                    },
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: textColor,
                    },
                    grid: {
                      display: false, // Remove vertical grid lines
                    },
                  },
                  y: {
                    ticks: {
                      color: textColor,
                    },
                    grid: {
                      display: false, // Remove horizontal grid lines
                    },
                  },
                },
                elements: {
                  bar: {
                    borderWidth: 1,
                    borderRadius: 4,
                    borderSkipped: false,
                  },
                },
              }}
            />
          </Box>

          {/* Key Metrics Tiles */}
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Key Metrics
            </Text>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
              {keyMetrics.map((metric) => (
                <Box
                  key={metric.id}
                  borderWidth="1px"
                  borderColor={borderColor}
                  borderRadius="md"
                  p={4}
                >
                  <HStack spacing={3}>
                    <Icon as={metric.icon} w={6} h={6} color={metric.color} />
                    <Text fontWeight="bold">{metric.title}</Text>
                  </HStack>
                  <Text fontSize="xl" fontWeight="bold" mt={2}>
                    {metric.value}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>

          {/* Category Sales Ranking Carousel */}
          <CategorySalesRankingCarousel
            data={sortedCategories}
            rankingBy={rankingBy}
            onToggleRanking={onToggleRanking}
          />
        </VStack>
      )}
    </Box>
  );
};

export default CategorySales;
