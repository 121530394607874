// ItemSales.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Heading,
  IconButton,
  Image,
  useDisclosure,
  Collapse,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FiArrowLeft, FiShoppingBag, FiDollarSign } from "react-icons/fi";
import { Line } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
Chart.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

// Placeholder function for API.get and getMediaURL
const API = {
  get: async (endpoint) => {
    // Mock API response based on the provided data
    if (endpoint === "/items") {
      return {
        data: {
          success: true,
          data: [
            // ... (Insert your actual item data here)
            {
              id: 1,
              name: "Burger",
              image: "https://via.placeholder.com/50",
              unitsSold: 150,
              revenue: 3000,
            },
            {
              id: 2,
              name: "Pasta",
              image: "https://via.placeholder.com/50",
              unitsSold: 120,
              revenue: 2400,
            },
            {
              id: 3,
              name: "Salad",
              image: "https://via.placeholder.com/50",
              unitsSold: 100,
              revenue: 2000,
            },
            {
              id: 4,
              name: "Soda",
              image: "https://via.placeholder.com/50",
              unitsSold: 200,
              revenue: 4000,
            },
            {
              id: 5,
              name: "Ice Cream",
              image: "https://via.placeholder.com/50",
              unitsSold: 80,
              revenue: 1600,
            },
            {
              id: 6,
              name: "Fries",
              image: "https://via.placeholder.com/50",
              unitsSold: 90,
              revenue: 1800,
            },
            {
              id: 7,
              name: "Pizza",
              image: "https://via.placeholder.com/50",
              unitsSold: 110,
              revenue: 2200,
            },
            {
              id: 8,
              name: "Steak",
              image: "https://via.placeholder.com/50",
              unitsSold: 70,
              revenue: 1400,
            },
            {
              id: 9,
              name: "Tacos",
              image: "https://via.placeholder.com/50",
              unitsSold: 130,
              revenue: 2600,
            },
            {
              id: 10,
              name: "Coffee",
              image: "https://via.placeholder.com/50",
              unitsSold: 160,
              revenue: 3200,
            },
          ],
        },
      };
    }
    return { data: { success: false, data: [] } };
  },
};

// Reusable InfoTile Component for Item Sales
const InfoTile = ({ title, subTitle, value, icon }) => {
  const { isOpen, onToggle } = useDisclosure();

  // Define color constants
  const tileBg = useColorModeValue("gray.100", "gray.700");
  const tileHoverBg = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");

  return (
    <Box
      bg={tileBg}
      borderRadius="md"
      p={6}
      cursor="pointer"
      _hover={{ bg: tileHoverBg }}
      transition="background 0.3s"
      onClick={onToggle}
      h="100%" // Make tile occupy full height
      w="100%"
    >
      <Flex align="center" justifyContent="space-between">
        <HStack spacing={3}>
          <Icon as={icon} w={8} h={8} color={textColor} />
          <VStack align="start" spacing={0}>
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              {title}
            </Text>
            <Text fontSize="sm" color={subTextColor}>
              {subTitle}
            </Text>
          </VStack>
        </HStack>
        <Text fontSize="3xl" fontWeight="bold" color={textColor}>
          {value}
        </Text>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box
          mt={4}
          p={4}
          bg={useColorModeValue("gray.50", "gray.600")}
          borderRadius="md"
        >
          <Text fontSize="sm" color={subTextColor}>
            Detailed information about {title.toLowerCase()} goes here.
          </Text>
        </Box>
      </Collapse>
    </Box>
  );
};

// LineChart Component
const LineChart = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const lineColor = useColorModeValue("blue.500", "lightgray"); // Main line color light grey for dark mode
  const pointBackgroundColor = useColorModeValue("blue.500", "blue.300");
  const gridColor = useColorModeValue("gray.200", "gray.600");
  const tooltipBgColor = useColorModeValue("white", "gray.700");
  const tooltipTextColor = useColorModeValue("gray.800", "white");

  // Prepare chart data
  const chartData = useMemo(
    () => ({
      labels: data.map((item) => item.item),
      datasets: [
        {
          label: "Revenue ($)",
          data: data.map(item => parseFloat(item.revenue)),
          fill: false,
          backgroundColor: lineColor,
          borderColor: lineColor,
          tension: 0.1,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBackgroundColor,
          pointHoverBackgroundColor: pointBackgroundColor,
          pointHoverBorderColor: pointBackgroundColor,
        },
      ],
    }),
    [data, lineColor, pointBackgroundColor]
  );

  // Chart options
  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: textColor,
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          enabled: true,
          backgroundColor: tooltipBgColor,
          titleColor: tooltipTextColor,
          bodyColor: tooltipTextColor,
          borderColor: lineColor,
          borderWidth: 1,
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColor,
            maxRotation: 0, // Keep labels horizontal
            minRotation: 0,
            font: {
              size: 12,
            },
            callback: function (value) {
              const label = this.getLabelForValue(value);
              return label.length > 10 ? label.substring(0, 10) + "..." : label;
            },
          },
          grid: {
            display: false, // Remove vertical grid lines
            color: gridColor,
          },
        },
        y: {
          ticks: {
            color: textColor,
            font: {
              size: 12,
            },
            beginAtZero: true,
          },
          grid: {
            display: false, // Remove horizontal grid lines
            color: gridColor,
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
        point: {
          radius: 4,
        },
      },
    }),
    [textColor, tooltipBgColor, tooltipTextColor, lineColor, gridColor]
  );

  return (
    <Box height="400px" w="100%">
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

// ItemsRankingGrid Component (using Grid)
const ItemsRankingGrid = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");
  const gridHeaderBg = useColorModeValue("gray.100", "gray.700");
  const gridRowBg = useColorModeValue("gray.50", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box w="100%" h="100%">
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
        alignItems="center"
        bg={gridHeaderBg}
        p={2}
        borderRadius="md"
      >
        <GridItem colSpan={1}>
          <Text fontWeight="bold" color={textColor}>
            Item
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Units Sold
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Revenue ($)
          </Text>
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
        mt={2}
        h="calc(100% - 40px)"
      >
        {data.slice(0, 5).map((item) => (
          <React.Fragment key={item.id}>
            {/* Item Name with Circular Image */}
            <GridItem>
              <HStack spacing={3}>
                <Box
                  position="relative"
                  borderRadius="full"
                  overflow="hidden"
                  width="40px"
                  height="40px"
                >
                  <Image
                    src={item.image}
                    alt={item.name}
                    boxSize="40px"
                    objectFit="cover"
                    fallbackSrc="https://via.placeholder.com/40"
                  />
                  {/* Gradient Overlay */}
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bgGradient="linear(to-t, rgba(0,0,0,0.2), transparent)"
                  />
                </Box>
                <Text color={textColor}>{item.item}</Text>
              </HStack>
            </GridItem>
            {/* Units Sold */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                {item.timesSold}
              </Text>
            </GridItem>
            {/* Revenue */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                ${parseFloat(item.revenue).toFixed(2)}
              </Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

// ItemSales Component
const ItemSales = () => {
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const { data } = location.state || {};

  // Define color constants
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const tableHeaderBg = useColorModeValue("gray.100", "gray.700");
  const tableRowBg = useColorModeValue("gray.50", "gray.600");
  const iconColor = useColorModeValue("gray.800", "white");
  const boxBgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const subTextColor = useColorModeValue("gray.600", "gray.400");

  useEffect(() => {
    console.log("itemSalesData", data);

    if (data && data.items) {
      // Sort items based on timesSold
      const sortedItems = [...data.items].sort(
        (a, b) => b.timesSold - a.timesSold
      );

      // Set itemData to sortedItems
      setItemData(sortedItems);

      setLoading(false);
    } else {
      setItemData([]);
      setLoading(false);
    }
  }, [data]);

  // Calculate total revenue
  const totalRevenue = useMemo(() => {
    return itemData.reduce((sum, item) => sum + parseFloat(item.revenue), 0);
  }, [itemData]);

  // Prepare data for the Line chart
  const lineChartData = useMemo(() => {
    if (!itemData) return [];

    // Take top 10 items based on revenue
    const topItems = itemData.slice(0, 10);

    return topItems.map((item) => ({
      item: item.item,
      revenue: parseFloat(item.revenue),
    }));
  }, [itemData]);

  return (
    <Box p={5} color={textColor} minH="100vh">
      {loading ? (
        <Flex justify="center" align="center" minH="80vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* Header with Back Button */}
          <Flex align="center">
            <IconButton
              icon={<FiArrowLeft />}
              variant="ghost"
              borderRadius={"full"}
              color={iconColor}
              onClick={() => navigate(-1)}
              aria-label="Go Back"
              mr={4}
              size="lg"
            />

            <VStack align="start" spacing={1}>
              <Heading size="xl" fontWeight="bold">
                Item Sales
              </Heading>
              <Text fontSize="md" color={subTextColor}>
                Analyze sales performance by items
              </Text>
            </VStack>
          </Flex>

          {/* Item Sales Tile and Items Ranking Grid */}
          <Flex direction={{ base: "column", md: "row" }} gap={6}>
            {/* Item Sales Tile */}
            <Box flex="1" minH="200px">
              <InfoTile
                title="Total Revenue"
                subTitle="Overall revenue from all items"
                value={`$${totalRevenue.toFixed(2)}`}
                icon={FiDollarSign}
              />
            </Box>

            {/* Items Ranking Grid */}
            <Box flex="1" minH="200px">
              <ItemsRankingGrid data={itemData} />
            </Box>
          </Flex>

          {/* Line Chart */}
          <Box
            borderWidth={"1px"}
            borderColor={borderColor}
            borderRadius="md"
            p={4}
            //boxShadow="md"
          >
            <Heading size="md" mb={4}>
              Revenue Over Time
            </Heading>
            <LineChart data={lineChartData} />
          </Box>

          {/* Items Sold and Revenue Table */}
          <Box
            borderWidth={"1px"}
            borderColor={borderColor}
            borderRadius="md"
            p={4}
            //boxShadow="md"
          >
            <Heading size="md" mb={4}>
              Items Sold and Revenue
            </Heading>
            <Box overflowX="auto">
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                {/* Table Headers */}
                <Box bg={tableHeaderBg} p={2} borderRadius="md">
                  <Text fontWeight="bold" color={textColor}>
                    Item
                  </Text>
                </Box>
                <Box bg={tableHeaderBg} p={2} borderRadius="md">
                  <Text fontWeight="bold" color={textColor} textAlign="right">
                    Units Sold
                  </Text>
                </Box>
                <Box bg={tableHeaderBg} p={2} borderRadius="md">
                  <Text fontWeight="bold" color={textColor} textAlign="right">
                    Revenue ($)
                  </Text>
                </Box>

                {/* Table Rows */}
                {itemData.map((item) => (
                  <>
                    <Box p={2} borderBottom="1px" borderColor={borderColor}>
                      <HStack spacing={3}>
                        <Box
                          position="relative"
                          borderRadius="full"
                          overflow="hidden"
                          width="40px"
                          height="40px"
                        >
                          <Image
                            src={"https://via.placeholder.com/40"}
                            alt={item.name}
                            boxSize="40px"
                            objectFit="cover"
                            fallbackSrc="https://via.placeholder.com/40"
                          />
                          {/* Gradient Overlay */}
                          <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bgGradient="linear(to-t, rgba(0,0,0,0.2), transparent)"
                          />
                        </Box>
                        <Text color={textColor}>{item.item}</Text>
                      </HStack>
                    </Box>
                    <Box p={2} borderBottom="1px" borderColor={borderColor}>
                      <Text color={textColor} textAlign="right">
                        {item.timesSold}
                      </Text>
                    </Box>
                    <Box p={2} borderBottom="1px" borderColor={borderColor}>
                      <Text color={textColor} textAlign="right">
                        ${parseFloat(item.revenue).toFixed(2)}
                      </Text>
                    </Box>
                  </>
                ))}
              </Grid>
            </Box>
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default ItemSales;
