import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Heading,
  Spinner,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import API from "../../context/API";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState("pending");
  const { colorMode } = useColorMode();

  useEffect(() => {
    fetchOrders(selectedTab);
  }, [selectedTab]);

  const fetchOrders = async (status) => {
    setLoading(true);
    try {
      let allOrders = [];

      if (status === "history") {
        const response = await API.get(`/orders?type=history`);
        if (response.data.success) {
          // If the response is an object with an "orders" array, extract the array
          allOrders = response.data.data.orders || [];
        }
      } else {
        const response = await API.get(`/orders?type=${status}`);
        if (response.data.success) {
          allOrders = response.data.data;
        }
      }

      setOrders(allOrders);
    } catch (error) {
      setError("An error occurred while fetching orders.");
      setOrders([]); // Set to empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleToggleCollapse = (id) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === id
          ? { ...order, isOpen: !order.isOpen }
          : { ...order, isOpen: false }
      )
    );
  };

  return (
    <Box padding="4">
      <Heading size="xl" letterSpacing="-2" marginBottom="4">
        Orders
      </Heading>
      <Tabs
        variant="enclosed"
        onChange={(index) =>
          setSelectedTab(["pending", "completed", "ready", "history"][index])
        }
      >
        <TabList>
          <Tab>Pending</Tab>
          <Tab>Completed</Tab>
          <Tab>Ready</Tab>
          <Tab>History</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No pending orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No completed orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No ready orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Box textAlign="center" mt="4">
                <Spinner size="xl" />
              </Box>
            ) : orders.length === 0 ? (
              <Text mt="4">No orders found.</Text>
            ) : (
              <OrderTable
                orders={orders}
                onToggleCollapse={handleToggleCollapse}
                colorMode={colorMode}
                showStatus
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

const OrderTable = ({
  orders,
  onToggleCollapse,
  showStatus = false,
  colorMode,
}) => (
  <Box overflowX="auto" borderRadius="md" mt="4">
    <Table variant="simple" minWidth="600px">
      <Thead>
        <Tr>
          <Th>Order ID</Th>
          <Th>User Name</Th>
          <Th>User Phone</Th>
          {showStatus && <Th>Status</Th>}
          <Th>Total Price</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {orders.map((order) => (
          <React.Fragment key={order.id}>
            <Tr>
              <Td>{order.id}</Td>
              <Td>{order.user_name}</Td>
              <Td>{order.user_phone}</Td>
              {showStatus && <Td>{order.status}</Td>}
              <Td>
                $
                {order?.total_price != null && !isNaN(order.total_price)
                  ? parseFloat(order.total_price).toFixed(2)
                  : "0.00"}
              </Td>
              <Td>
                <Button
                  variant="link"
                  onClick={() => onToggleCollapse(order.id)}
                  rightIcon={
                    order.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                  }
                >
                  {order.isOpen ? "Hide Details" : "View Details"}
                </Button>
              </Td>
            </Tr>
            <Tr>
              <Td colSpan={showStatus ? 6 : 5} p="0">
                <Collapse in={order.isOpen}>
                  <Box p="4" borderWidth="1px" borderRadius="md" mb="4">
                    <Heading size="md" mb="4">
                      Order Items
                    </Heading>
                    <VStack align="start" spacing="4">
                      {order.items.map((item) => (
                        <Box
                          key={item.id}
                          padding="2"
                          borderWidth="1px"
                          borderRadius="md"
                          w="100%"
                          bg={colorMode === "light" ? "gray.50" : "gray.700"}
                        >
                          <Text fontWeight="bold">{item.item.item}</Text>
                          <Text>Quantity: {item.quantity}</Text>
                          <Text>
                            Price: $
                            {item?.itemPrice != null && !isNaN(item.itemPrice)
                              ? parseFloat(item.itemPrice).toFixed(2)
                              : "0.00"}
                          </Text>

                          {item.orderItemCustomizations.map((customization) => (
                            <Box
                              key={customization.id}
                              mt="2"
                              ml="4"
                              pl="4"
                              borderLeft="2px solid gray"
                            >
                              <Text
                                fontSize="sm"
                                color={
                                  colorMode === "light"
                                    ? "gray.600"
                                    : "gray.200"
                                }
                              >
                                {customization.customization.customization}:
                                {customization.item.item} (+$
                                {customization?.itemPrice != null &&
                                !isNaN(customization.itemPrice)
                                  ? parseFloat(customization.itemPrice).toFixed(
                                      2
                                    )
                                  : "0.00"}
                                )
                              </Text>
                              <Text
                                fontSize="sm"
                                color={
                                  colorMode === "light"
                                    ? "gray.600"
                                    : "gray.200"
                                }
                                ml="4"
                                mt="1"
                              >
                                - Customization: {customization.item.option} (+$
                                {customization?.item?.price != null &&
                                !isNaN(customization.item.price)
                                  ? parseFloat(
                                      customization.item.price
                                    ).toFixed(2)
                                  : "0.00"}
                                )
                              </Text>
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </VStack>
                  </Box>
                </Collapse>
              </Td>
            </Tr>
          </React.Fragment>
        ))}
      </Tbody>
    </Table>
  </Box>
);

export default Orders;
