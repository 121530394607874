import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  NumberInput,
  NumberInputField,
  Switch,
  VStack,
  Button,
  HStack,
  useColorModeValue,
  useToast,
  SlideFade,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

const CustomizationRules = ({
  customizationData,
  handleBackClick,
  handleSubmit,
  handleInputChange,
}) => {
  const [minQuantity, setMinQuantity] = useState(
    customizationData.min_quantity || 0
  );
  const [maxQuantity, setMaxQuantity] = useState(
    customizationData.max_quantity || 1
  );
  const [multipleSelections, setMultipleSelections] = useState(
    customizationData.multiple_selections || false
  );
  const [multipleSelectionsQuantity, setMultipleSelectionsQuantity] = useState(
    customizationData.multiple_selections_quantity || 1
  );

  const toast = useToast();
  const boxBg = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.700");

  const saveChanges = async () => {
    const updatedCustomizationData = {
      ...customizationData,
      min_quantity: minQuantity,
      max_quantity: maxQuantity,
      multiple_selections: multipleSelections,
      multiple_selections_quantity: multipleSelectionsQuantity,
    };
    handleSubmit(updatedCustomizationData);
    toast({
      title: "Customization rules updated.",
      description: "The customization rules have been updated successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box
      p={4}
      //display="flex"
      flexDirection="column"
      width={"25%"}
      position={"fixed"}
      right={4}
      h={`calc(100vh - 120px)`}
      maxWidth={"inherit"}
      borderLeft="1px solid"
      borderColor={borderColor}
      overflowY={"auto"}
    >
      <SlideFade in={true} offsetY="20px">
        <HStack mb={4} spacing={2}>
          <ChevronLeftIcon
            w={6}
            h={6}
            cursor="pointer"
            onClick={handleBackClick}
          />
          <Text
            cursor="pointer"
            onClick={handleBackClick}
            _hover={{ textDecoration: "underline" }}
          >
            Customisations
          </Text>
          <Text> / Customization rules</Text>
        </HStack>

        <Heading size="md" mb={4}>
          Customisation rules
        </Heading>

        <Text mb={4}>Set limits for customising items</Text>

        <VStack spacing={4} align="start">
          <Box>
            <Text fontWeight="medium">Minimum quantity</Text>
            <Text mb={2}>
              What’s the minimum number of options a customer must select?
            </Text>
            <NumberInput
              min={0}
              value={minQuantity}
              onChange={(valueString) => setMinQuantity(Number(valueString))}
            >
              <NumberInputField />
            </NumberInput>
          </Box>

          <Box>
            <Text fontWeight="medium">Maximum quantity</Text>
            <Text mb={2}>
              What’s the maximum number of options a customer can select?
            </Text>
            <NumberInput
              min={1}
              value={maxQuantity}
              onChange={(valueString) => setMaxQuantity(Number(valueString))}
            >
              <NumberInputField />
            </NumberInput>
          </Box>

          <Box>
            <Text fontWeight="medium">Multiple selections</Text>
            <Text mb={2}>Can customers select the options more than once?</Text>
            <Switch
              isChecked={multipleSelections}
              onChange={(e) => setMultipleSelections(e.target.checked)}
            />
          </Box>

          {multipleSelections && (
            <Box>
              <Text fontWeight="medium">
                How many times can customers select any single option?
              </Text>
              <NumberInput
                min={1}
                value={multipleSelectionsQuantity}
                onChange={(valueString) =>
                  setMultipleSelectionsQuantity(Number(valueString))
                }
              >
                <NumberInputField />
              </NumberInput>
            </Box>
          )}
        </VStack>

        <Button
          mt={6}
          width="full"
          bg="black"
          color="white"
          _hover={{
            bg: "white",
            color: "black",
            borderColor: "gray.300",
            borderWidth: "1px",
          }}
          onClick={saveChanges}
        >
          Save
        </Button>
      </SlideFade>
    </Box>
  );
};

export default CustomizationRules;
