import React from "react";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./components/LoginPage";
import Merchants from "./components/Merchants/Merchants";
import MerchantManager from "./components/Merchants/MerchantManager";
import Devices from "./components/Devices/Devices";
import DeviceTypes from "./components/DeviceTypes/DeviceTypes";
import Dashboard from "./components/Dashboard/Dashboard";
import Staff from "./components/Staff/Staff";
import AnalyticsPage from "./components/Analytics/Analytics";
import PaymentsAdmin from "./components/Payments/PaymentsAdmin";
import { Sales } from "./components/Sales/Sales";
import Operations from "./components/Operations/Operations.js";
import SettingsPage from "./components/Settings/Settings";
import Reports from "./components/Reports/Reports";
import Profile from "./components/Profile";
import HelpSupport from "./components/HelpSupport";
import Items from "./components/Items/Items";
import Customizations from "./components/Menu/Customisations/Customisations";
import Orders from "./components/Orders/Orders";
import DeviceAssignment from "./components/Devices/DeviceAssignment.js";
import Schedules from "./components/Schedules/Schedules.js";
import Menus from "./components/Menu/Menus.js";
import Categories from "./components/Menu/Categories.js";
import Menu from "./components/Menu/Menu.js";
import MerchantAnalytics from "./components/Analytics/merchantAnalytics.js";
import SalesDetailedView from "./components/Analytics/SalesDetailedView.js";
import CategorySales from "./components/Analytics/CategorySales.js";
import ItemSales from "./components/Analytics/ItemSales.js";
import Customers from "./components/Analytics/Customers.js";
import Disputes from "./components/Analytics/Disputes.js";

const staticAdminRoutes = [
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/merchants',
        component: Merchants,
    },
    {
        path: '/devices',
        component: Devices,
    },
    {
        path: '/device-types',
        component: DeviceTypes,
    },
    {
        path: '/dashboard',
        component: Dashboard,
    },
    {
        path: '/merchantmanager',
        component: MerchantManager,
    },
    {
        path: '/staff',
        component: Staff,
    },
    {
        path: '/analytics',
        component: AnalyticsPage,
    },
    {
        path: '/payments',
        component: PaymentsAdmin,
    },
    {
        path: '/sales',
        component: Sales,
    },
    {
        path: '/operations',
        component: Operations,
    },
    {
        path: '/settings',
        component: SettingsPage,
    },
    {
        path: '/reports',
        component: Reports,
    },
    {
        path: '/help-support',
        component: HelpSupport,
    },
    {
        path: '/profile',
        component: Profile,
    },
    {
        path: '/items',
        component: Items,
    },
    {
        path: '/customizations',
        component: Customizations,
    },
    {
        path: '/orders',
        component: Orders,
    },
    {
        path: '/deviceassignment',
        component: DeviceAssignment,
    },
    {
        path: '/schedules',
        component: Schedules,
    },
    {
        path: '/menus',
        component: Menus,
    },
    {
        path: '/categories',
        component: Categories,
    },
    {
        path: '/menu',
        component: Menu,
    },
    {
        path: '/analytics',
        component: MerchantAnalytics,
      },
      {
        path: '/analytics/sales',
        component: SalesDetailedView,
      },
      {
        path: '/analytics/category-sales',
        component: CategorySales,
      },
      {
        path: '/analytics/item-sales',
        component: ItemSales,
      },
      {
        path: '/analytics/customers',
        component: Customers,
      },
      {
        path: '/analytics/disputes',
        component: Disputes,
      },
];

const roleBasedComponents = {};

function AppRoutes({ dynamicRoutes, role }) {
    const roleComponents = roleBasedComponents[role] || {};

    const allRoutes = [
        ...staticAdminRoutes,
        ...dynamicRoutes.map(item => {
           
            return {
                path: item.slug,
                component: roleComponents[item.page.toLowerCase()] || (() => <div>{item.page}</div>)
            };
        })
    ];


    return (
        <>
            <Routes>
                {allRoutes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={React.createElement(route.component, {role})}
                    />
                ))}
            </Routes>
        </>
    );
}

export default AppRoutes;
