import React, { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  useColorMode,
  List,
  ListItem,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tbody,
} from "@chakra-ui/react";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import API, { getMediaURL } from "../../context/API";
import { GrUpdate } from "react-icons/gr";
import { motion, Reorder } from "framer-motion";

const CategoriesSearchBar = ({
  items,
  onSearch,
  onSuggestionClick,
  placeholder = "Search...",
  selectedItems = [], // Add selectedItems with default empty array
  ...props
}) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { colorMode } = useColorMode();
  const searchBarRef = useRef(null); // Reference for the search bar

  // Fetch or generate suggestions based on the input query
  const fetchSuggestions = (query) => {
    return items.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
  };

  useEffect(() => {
    if (query) {
      setSuggestions(fetchSuggestions(query));
    } else {
      setSuggestions([]);
    }
  }, [query, items]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearch(query);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedItems.some((item) => item.item === suggestion)) {
      onSuggestionClick(suggestion);
    }

    setQuery(suggestion);
    setSuggestions([]);
    onSearch(suggestion);
  };

  const clearSearch = () => {
    setQuery("");
    setSuggestions([]);
    onSearch(""); // Optionally reset the search
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setSuggestions([]); // Close the suggestions when clicking outside
    }
  };

  const renderSuggestions = () => {
    return suggestions.map((suggestion, index) => {
      const isSelected = selectedItems.some((item) => item.item === suggestion);

      return (
        <ListItem
          key={index}
          p={3}
          cursor="pointer"
          borderRadius="md"
          _hover={{ bg: suggestionHoverBg }}
          color={suggestionTextColor}
          onClick={() => handleSuggestionClick(suggestion)}
        >
          <Flex alignItems="center">
            {suggestion}
            {isSelected && <CheckIcon ml="auto" color="green.500" />}
          </Flex>
        </ListItem>
      );
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const suggestionBg = colorMode === "light" ? "whiteAlpha.900" : "gray.700";
  const suggestionHoverBg = colorMode === "light" ? "gray.100" : "gray.600";
  const suggestionTextColor = colorMode === "light" ? "gray.800" : "gray.200";

  return (
    <Box position="relative" ref={searchBarRef} {...props}>
      <InputGroup mb="4">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.500" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="filled"
          borderRadius="full"
          {...props}
        />
        {query && (
          <InputRightElement>
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              onClick={clearSearch}
              variant="ghost"
              aria-label="Clear search"
            />
          </InputRightElement>
        )}
      </InputGroup>
      {suggestions.length > 0 && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          right="0"
          zIndex="10"
          bg={suggestionBg}
          borderRadius="lg"
          boxShadow="lg"
          maxHeight="200px"
          overflowY="auto"
          backdropFilter="blur(10px)" // Adds a blur effect to the background
        >
          <List spacing={1}>{renderSuggestions()}</List>
        </Box>
      )}
    </Box>
  );
};

const Categories = ({ onCategoryChanged }) => {
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]); // State to store all items
  const [selectedItems, setSelectedItems] = useState([]); // State for selected items
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false); // Separate state for refresh loading
  const [error, setError] = useState(null);
  const [currentCategory, setCurrentCategory] = useState({
    category: "",
    picture: "",
    sequence_number: 0,
    items: [],
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [imageFile, setImageFile] = useState(null);
  const imageInputRef = useRef(null);

  // Add a ref to track the order of categories
  const [categoryOrder, setCategoryOrder] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await API.get("/items");
      setItems(response.data.data); // Store fetched items
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchCategories = async () => {
    setRefreshing(true);

    try {
      const response = await API.get("/categories");
      const categoriesData = response.data.data.map((category) => ({
        ...category,
        picture: getMediaURL(category.picture), // Use the helper function here
        sequence_number: category.sequence_number || 0, // Ensure sequence_number is a number
      }));
      categoriesData.sort((a, b) => a.sequence_number - b.sequence_number);

          // Fetch items for each category
    const categoriesWithItems = await Promise.all(
      categoriesData.map(async (category) => {
        try {
          const response = await API.get(`/categories/${category.id}/items`);
          const categoryItems = response.data.data;
          const itemIds = categoryItems.map((item) => item.id.toString());
          return {
            ...category,
            items: itemIds,
          };
        } catch (error) {
          console.error(`Error fetching items for category ${category.id}:`, error);
          return {
            ...category,
            items: [],
          };
        }
      })
    );

      setCategoryOrder(categoriesWithItems.map((category) => category.id));
    setCategories(categoriesWithItems);

    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleItemSelect = (selectedItemName) => {
    const selectedItem = items.find((item) => item.item === selectedItemName);
    if (
      selectedItem &&
      !selectedItems.some((item) => item.id === selectedItem.id)
    ) {
      setSelectedItems([...selectedItems, selectedItem]);
      setCurrentCategory({
        ...currentCategory,
        items: [...selectedItems, selectedItem],
      });
    }
  };

  const handleSearch = (query) => {
    // You can implement any specific logic here if you need to do something when the user presses Enter
    console.log("Search query:", query);
  };

  const handleRemoveSelectedItem = (itemId) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== itemId));
  };

  const handleEdit = async (category) => {
    setCurrentCategory(category);
    //setSelectedItems(category.items || []); // Set selected items for editing
    setImageFile(null); // Reset image file state

    try {
      // Fetch items associated with the category
      const response = await API.get(`/categories/${category.id}/items`);
      const categoryItems = response.data.data;

      // Update selectedItems with the fetched items
      setSelectedItems(categoryItems);
    } catch (error) {
      console.error("Error fetching category items:", error);
      toast({
        title: "Error fetching category items.",
        description: "Unable to fetch items for this category.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    onOpen();
  };

  const handleCreate = () => {
    setCurrentCategory({
      category: "",
      picture: "",
      items: [],
    });
    setSelectedItems([]); // Reset selected items

    setImageFile(null); // Reset image file state
    onOpen();
  };

  const handleDelete = (id) => {
    setCurrentCategory({ ...currentCategory, id });
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await API.delete(`/categories/${currentCategory.id}`);
      toast({
        title: "Category deleted.",
        description: "The category has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchCategories();
    } catch (error) {
      toast({
        title: "Error deleting category.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
    }
  };
  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "kiwikiosk_categories");

    try {
      const response = await API.post("/images/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fullUrl = response.data.url;
      const relativePath = cleanPicturePath(fullUrl);
      //console.log("Image uploaded. Full URL:", fullUrl, "Relative Path:", relativePath);
      return relativePath;
    } catch (error) {
      console.error('Error uploading image:', error);
      const errorMessage = error.response?.data?.message || error.response?.data || "Unable to upload the image.";
  
      toast({
        title: "Image Upload Failed.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

  const handleImageReplace = async (imageKey, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "kiwikiosk_categories");

    try {
      const encodedImageKey = encodeURIComponent(imageKey);
      //console.log('Replacing image with imageKey:', imageKey);
      const response = await API.put(`/images/${encodedImageKey}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fullUrl = response.data.url;
      const relativePath = cleanPicturePath(fullUrl);
      //console.log("Image uploaded. Full URL:", fullUrl, "Relative Path:", relativePath);
      return relativePath;
    } catch (error) {
      console.error('Error replacing image:', error);
      const errorMessage = error.response?.data?.message || error.response?.data || "Unable to replace the image.";
  
      toast({
        title: "Image Replace Failed.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

   // Define the cleanPicturePath function
   const cleanPicturePath = (path) => {
    const baseURL = "https://nyc3.digitaloceanspaces.com/kiwikiosk/media/";
    if (!path || path.trim() === "") {
      return "default.jpg";
    }
    if (path.startsWith(baseURL)) {
      return path.substring(baseURL.length);
    } else {
      // If the path doesn't contain the baseURL, attempt to remove any protocol and domain
      const urlParts = path.split(/https?:\/\/[^/]+\//);
      return urlParts[urlParts.length - 1];
    }
  };

  const handleSubmit = async () => {

    let picturePath = currentCategory.picture;

    if (imageFile) {
      if (currentCategory.id) {
        // Existing category, replace the image
        const imageKey = cleanPicturePath(picturePath); // Clean the picturePath before extracting imageKey
        picturePath = await handleImageReplace(imageKey, imageFile);
      } else {
        // New category, upload the image
        picturePath = await handleImageUpload(imageFile);
      }
    } else {
      // No image uploaded
      if (!picturePath || picturePath.trim() === "") {
        // Set default image if picture is empty
        picturePath = "default.jpg";
      } else {
        // Clean existing picturePath
        picturePath = cleanPicturePath(picturePath);
      }
      // Use existing picturePath
    }

    if (picturePath) {
      picturePath = cleanPicturePath(picturePath);
      // Automatically assign sequence_number
      let newSequenceNumber;
      if (currentCategory.id) {
        // Existing category, keep existing sequence_number
        newSequenceNumber = currentCategory.sequence_number || 0;
      } else {
        // New category, assign sequence_number as max + 1
        const maxSequenceNumber = categories.reduce((max, category) => {
          const seqNum = category.sequence_number || 0;
          return Math.max(max, seqNum);
        }, 0);
        newSequenceNumber = maxSequenceNumber + 1;
      }

      // Map selectedItems to an array of item IDs in string format
      const itemIds = selectedItems.map((item) => item.id.toString());

      const formattedCategoryData = {
        ...currentCategory,
        category: currentCategory.category,
        picture: picturePath, // Only store the end path
        //items: selectedItems, // Include selected items in the API request
        sequence_number: newSequenceNumber, // Place this after spreading currentCategory
        items: itemIds, // Include items in both POST and PUT requests
      };

      console.log("formatted data", formattedCategoryData);
      try {
        if (currentCategory.id) {
          await API.put(
            `/categories/${currentCategory.id}`,
            formattedCategoryData
          );
          toast({
            title: "Category updated.",
            description: "The category has been updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          await API.post("/categories", formattedCategoryData);
          toast({
            title: "Category created.",
            description: "The category has been created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          // Notify Menu.js to refresh MenuOverview
        if (onCategoryChanged) {
          onCategoryChanged();
        }

        }
        fetchCategories(); // Refresh the list
        onClose();
      } catch (error) {
        console.error("Error saving category:", error);
        let errorMessage =
          error.response?.data?.message ||
          error.response?.data ||
          "Unable to save the category.";

        // Check and replace specific error messages
        if (errorMessage.includes("value too long for type")) {
          errorMessage = "The image file name is too long.";
        }

        toast({
          title: "An error occurred.",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Handle reorder of categories
  const handleReorder = async (newOrder) => {
    setCategoryOrder(newOrder);

    // Update categories with new sequence numbers
    const reorderedCategories = newOrder.map((id, index) => {
      const category = categories.find((cat) => cat.id === id);
      return {
        ...category,
        sequence_number: index + 1,
      };
    });

    setCategories(reorderedCategories);

    // Send updated sequence numbers to the backend
    try {
      await Promise.all(
        reorderedCategories.map((category) =>
          API.put(`/categories/${category.id}`, {
            id: category.id,
            category: category.category,
            //picture: category.picture.replace(getMediaURL(''), ''), // Adjust picture path
            sequence_number: category.sequence_number,
            items: category.items,
          })
        )
      );
      toast({
        title: "Categories reordered.",
        description: "The categories have been reordered successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating sequence numbers:", error);
      toast({
        title: "Error updating order.",
        description: "Unable to update the category order.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const hoverBgColor = useColorModeValue("gray.200", "gray.700");
  const selectedBgColor = useColorModeValue("gray.200", "gray.600");

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <Flex justify="space-between" align="center" marginBottom="4">
        <Box>
          <Heading size={"xl"} letterSpacing={-2}>
            Categories
          </Heading>
          <Text fontSize={"sm"}>
            List of all available categories, you can add new ones, edit and/or
            delete older ones.
          </Text>
        </Box>
        <Button
          display={{ base: "none", md: "flex" }} // Hide on mobile, show on larger screens
          leftIcon={<AddIcon />}
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          variant="outline"
          onClick={handleCreate}
        >
          Create Category
        </Button>
        <IconButton
          display={{ base: "flex", md: "none" }} // Show icon button on mobile
          icon={<AddIcon />}
          size="sm"
          aria-label="Create Category"
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          onClick={handleCreate}
        />
      </Flex>
      <Box p={4} bg={bgColor} borderRadius="md" overflowX="auto">
        <Table variant="simple" minWidth="600px">
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Picture</Th>
              <Th textAlign={"center"}>Actions</Th>
            </Tr>
          </Thead>
          {refreshing ? (
          <Tbody>
            <Tr>
              <Td colSpan="5">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  height="150px"
                >
                  <Spinner size="lg" />
                </Flex>
              </Td>
            </Tr>
            </Tbody>
          ) : (
            <Reorder.Group
              as="tbody"
              axis="y"
              values={categoryOrder}
              onReorder={handleReorder}
            >
              {categoryOrder.map((id) => {
                const category = categories.find((cat) => cat.id === id);
                return (
                  <Reorder.Item
                    as={motion.tr}
                    key={id}
                    value={id}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.01 }}
                    _hover={{
                      bg: hoverBgColor,
                      transition: "background-color 0.3s ease",
                    }}
                    bg={
                      category.id === currentCategory.id
                        ? selectedBgColor
                        : bgColor
                    }
                    style={{ cursor: "grab" }}
                  >
                    <Td>{category.category}</Td>
                    <Td>
                      {category.picture && (
                        <Image
                          src={category.picture}
                          alt={category.category}
                          boxSize="50px"
                          objectFit="cover"
                          borderRadius="md"
                          fallbackSrc="https://via.placeholder.com/50"
                        />
                      )}
                    </Td>
                    <Td>
                      <Flex justifyContent="center">
                        <IconButton
                          icon={<EditIcon />}
                          colorScheme="blue"
                          variant="ghost"
                          aria-label="Edit Category"
                          onClick={() => handleEdit(category)}
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          variant="ghost"
                          aria-label="Delete Category"
                          onClick={() => handleDelete(category.id)}
                        />
                      </Flex>
                    </Td>
                  </Reorder.Item>
                );
              })}
            </Reorder.Group>
          )}
        </Table>
      </Box>

      {/* Modal for Creating/Updating Category */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "full", md: "lg", lg: "4xl" }} // Responsive modal sizes
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentCategory.id ? "Update Category" : "Create Category"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="category" mb="4">
              <FormLabel>Category Name</FormLabel>
              <Input
                placeholder="Enter category name"
                value={currentCategory.category}
                onChange={(e) =>
                  setCurrentCategory({
                    ...currentCategory,
                    category: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl id="picture" mb="4">
              <FormLabel>Photo</FormLabel>
              <Box position="relative">
                <Image
                  src={
                    imageFile
                      ? URL.createObjectURL(imageFile)
                      : currentCategory.picture
                      ? getMediaURL(currentCategory.picture)
                      : "https://via.placeholder.com/320x240"
                  }
                  alt="Category Image"
                  width="100%"
                  height="300"
                  objectFit="cover"
                  borderRadius="md"
                  fallbackSrc="https://via.placeholder.com/320x240"
                />
                <Button
                  onClick={() => imageInputRef.current.click()}
                  position="absolute"
                  leftIcon={currentCategory.id ? <GrUpdate /> : <AddIcon />}
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  top="2"
                  right="2"
                  size={"sm"}
                  borderRadius={"full"}
                >
                  {currentCategory.id ? "Change image" : "Add image"}
                </Button>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={imageInputRef}
                  style={{ display: "none" }}
                />
              </Box>
              <Text fontSize="sm" color="gray.500" mt={2}>
                File requirement: JPG, PNG, GIF, or WEBP up to 10 MB. Minimum
                pixels required: 320 for width and height.
              </Text>
            </FormControl>

            {/* Items Dropdown with SearchBar */}
            <FormControl id="items" mb="4">
              <FormLabel>Items</FormLabel>
              <CategoriesSearchBar
                items={items.map((item) => item.item)}
                onSearch={handleSearch}
                onSuggestionClick={handleItemSelect}
                placeholder="Search and add items..."
                selectedItems={selectedItems} // Pass selectedItems as a prop
              />

              {/* Display Selected Items */}
              <Flex wrap="wrap" mt="4">
                {selectedItems.map((item) => (
                  <Button
                    key={item.id}
                    size="sm"
                    m="1"
                    colorScheme="blue"
                    onClick={() => handleRemoveSelectedItem(item.id)}
                    rightIcon={<CloseIcon boxSize={2} />}
                  >
                    {item.item}
                  </Button>
                ))}
              </Flex>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              mr={"3"}
              onClick={handleSubmit}
            >
              {currentCategory.id ? "Update Category" : "Create Category"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert dialog box for deleting confirmation */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Category
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Categories;
