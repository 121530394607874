import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {ChevronRightIcon, CloseIcon, EditIcon} from "@chakra-ui/icons";
import {FiMoreVertical} from "react-icons/fi";
import API from "../../../context/API";
import EditOptions from "./EditOptions";
import AddToItems from "./AddToItems";
import CustomizationRules from "./CustomizationRules";

const CustomizationSideBox = ({
  customizationData,
  handleInputChange,
  handleDelete,
  handleDuplicate,
  handleSeeChanges,
  onClose,
  isOpen,
  handleSubmit,
  setCustomizationData,
  handleEdit,
  isNewCustomization,
  selectedItem,
  handleRefresh
}) => {
  const { colorMode } = useColorMode();

  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editOptionsMode, setEditOptionsMode] = useState(false); 
  const [addToItemsMode, setAddToItemsMode] = useState(false); 
  const [customizationRulesMode, setCustomizationRulesMode] = useState(false); 

  const borderColor = useColorModeValue("gray.300", "gray.700");

  useEffect(() => {
    if (isNewCustomization) {
      setSelectedOptions([]);
    }
  }, [isNewCustomization]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await API.get("/options"); // Changed endpoint to /options
        if (response.data.success) {
          setOptions(response.data.data);
          setFilteredOptions(response.data.data); // Initialize filtered options
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleEditNameSave = () => {
    handleSubmit();
    setIsEditModalOpen(false);
  }
  


  useEffect(() => {
    if (customizationData && customizationData.options && options.length > 0) {
      const selectedOptionItems = options.filter((option) =>
        customizationData.options.includes(option.id.toString())
      );
      setSelectedOptions(selectedOptionItems);
    }
  }, [customizationData.options, options]);

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleEditOptionsClick = () => {
    setEditOptionsMode(true); // Switch to the EditOptions component
  };

  const handleCustomizationRulesClick = () => {
    setCustomizationRulesMode(true); // Switch to the EditOptions component
  };

  const handleAddToItemsClick = () => {
    setAddToItemsMode(true); // Switch to the EditOptions component
  };

  const handleBackClick = () => {
    setEditOptionsMode(false); // Switch back to the CustomizationSideBox component
    setAddToItemsMode(false);
    setCustomizationRulesMode(false);
  };

  const handleRelatedItemsChange = (updatedItems) => {
    setCustomizationData((prevData) => ({
      ...prevData,
      related_items: updatedItems,
    }));
  };

  if (editOptionsMode) {
    return (
      <EditOptions
        customizationData={customizationData}
        handleBackClick={handleBackClick}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        selectedItem={selectedItem}
        handleRefresh={handleRefresh}
      />
    );
  }

  if (addToItemsMode) {
    return (
      <AddToItems
        customizationData={customizationData}
        handleBackClick={handleBackClick}
        handleSubmit={handleSubmit}
        handleRelatedItemsChange={handleRelatedItemsChange}
      />
    );
  }

  if (customizationRulesMode) {
    return (
      <CustomizationRules
        customizationData={customizationData}
        handleBackClick={handleBackClick}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
      />
    );
  }
  return (
    <Box
      width={"25%"}
      position={"fixed"}
      right={4}
      h={`calc(100vh - 120px)`}
      maxWidth={"inherit"}
      borderLeft="1px solid"
      borderColor={borderColor}
      overflowY={"auto"}
    >
      <SlideFade in={true} offsetY="20px">
        <Box p={4}>
          <HStack justify="space-between" mb={4}>
            <HStack>
              <IconButton icon={<CloseIcon />} onClick={onClose} size={"sm"} />
              <Heading size="md">Customisations</Heading>
            </HStack>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<FiMoreVertical />}
                variant="ghost"
              />
              <MenuList>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <VStack align="start" spacing={4}>
            <Box w="full">
              <HStack justify="space-between" mb={1}>
                <Text fontSize="md" fontWeight="bold">
                  Customization Name
                </Text>
                <IconButton
                  size="sm"
                  variant="ghost"
                  icon={<EditIcon />}
                  onClick={openEditModal}
                />
              </HStack>
              <Text
                fontSize="sm"
                bg={colorMode === "light" ? "gray.100" : "gray.700"}
                p={2}
                borderRadius="md"
              >
                {customizationData.customization}
              </Text>
            </Box>

            <Button
              width="full"
              variant="outline"
              justifyContent="space-between"
              rightIcon={<ChevronRightIcon />}
              onClick={handleEditOptionsClick} // Trigger the EditOptions component
            >
              Edit options
            </Button>
            <Text px={4}>
              Manage which options are available to customers in this set.
            </Text>

            <Button
              width="full"
              variant="outline"
              justifyContent="space-between"
              rightIcon={<ChevronRightIcon />}
              onClick={handleAddToItemsClick}
            >
              Add to items
            </Button>

            <Button
              width="full"
              variant="outline"
              justifyContent="space-between"
              rightIcon={<ChevronRightIcon />}
              onClick={handleCustomizationRulesClick}
            >
              Customisation rules
            </Button>
          </VStack>

          {/* Modal for editing customization name */}
          <Modal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            isCentered
            size={"xl"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit Customization Name</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl id="customization-name" mb="4" isRequired>
                  <FormLabel>Customization Name</FormLabel>
                  <Input
                    placeholder="Enter customization name"
                    value={customizationData.customization}
                    onChange={(e) =>
                      handleInputChange("customization", e.target.value)
                    }
                    variant={"filled"}
                    borderRadius={"full"}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  //leftIcon={<AddIcon />}
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  onClick={handleEditNameSave}
                >
                  Save
                </Button>
                <Button onClick={closeEditModal} ml={3}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Modal for creating or editing customization */}
          <Modal
            isOpen={isOpen && isNewCustomization}
            onClose={onClose}
            isCentered
            size={"xl"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {isNewCustomization
                  ? "Create Customization"
                  : "Edit Customization"}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl id="customization-name" mb="4" isRequired>
                  <FormLabel>Customization Name</FormLabel>
                  <Input
                    placeholder="Enter customization name"
                    value={customizationData.customization}
                    onChange={(e) =>
                      handleInputChange("customization", e.target.value)
                    }
                    variant={"filled"}
                    borderRadius={"full"}
                  />
                </FormControl>

                <FormControl id="note" mb="4">
                  <FormLabel>Note</FormLabel>
                  <Input
                    placeholder="Optional note"
                    value={customizationData.note}
                    onChange={(e) => handleInputChange("note", e.target.value)}
                    variant={"filled"}
                    borderRadius={"full"}
                  />
                </FormControl>

              </ModalBody>
              <ModalFooter>
                <Button
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                <Button onClick={onClose} ml={3}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </SlideFade>
    </Box>
  );
};

export default CustomizationSideBox;
