import {
  Box,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FaClock, FaEye } from "react-icons/fa";

import Categories from "./Categories";
import Customizations from "./Customisations/Customisations";
import SoldOut from "./SoldOut";
import API from "../../context/API";
import Menus from "./Menus";
import Items from "../Items/Items";
import Schedules from "../Schedules/Schedules";
import MenuOverview from "./MenuOverview";
import SideBox from "./SideBox";
import { motion } from "framer-motion";
import { BsBagXFill } from "react-icons/bs";

const MotionBox = motion(Box);

function Menu() {
  const [editingItem, setEditingItem] = useState(null);
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [itemData, setItemData] = useState({
    item: "",
    name: "",
    description: "",
    sequence_number: "",
    price: "",
    //picture: "",
  });
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [imageFile, setImageFile] = useState(null);
  const imageInputRef = useRef(null);

  // Add state variables for tab index and selected customization ID
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCustomizationId, setSelectedCustomizationId] = useState(null);
  const [dataNeedsRefresh, setDataNeedsRefresh] = useState(false); 

  const [categoriesToRefetch, setCategoriesToRefetch] = useState([]);

  const [menuOverviewKey, setMenuOverviewKey] = useState(0);

  // Handler function to update the key
  const handleMenuOverviewRefresh = () => {
    setMenuOverviewKey((prevKey) => prevKey + 1);
  };

  // Handle tab change
  const handleTabChange = (index) => {
    setTabIndex(index);

    // If moving to Categories (index === 2) or Items (index === 3)
    if (index === 2 || index === 3) {
      setDataNeedsRefresh(true);
    }

    // If moving back to Overview tab (index === 0)
    if (index === 0 && dataNeedsRefresh) {
      setDataNeedsRefresh(false);
      setMenuOverviewKey((prevKey) => prevKey + 1); // Force MenuOverview to remount
      handleCloseSideBox(); // Close the SideBox after saving
    }
    // If navigating away from Customizations tab, reset selectedCustomizationId

    if (index !== 5) {
      // Assuming Customizations tab is at index 5
      setSelectedCustomizationId(null);
    }
  };

  // Handle customization click from SideBox
  const handleCustomizationClick = (customizationId) => {
    setSelectedCustomizationId(customizationId);
    setTabIndex(5); // Switch to Customizations tab

    console.log("tab change initiated");
  };

  const handleAddItem = () => {
    setEditingItem(null); // Reset the editing item
    setItemData({
      item: "",
      name: "", // During editing, this should not be editable or even required
      description: "",
      sequence_number: "",
      price: "",
      picture: "",
    });
    setImageFile(null); // Clear image file when creating a new item
    setIsAddItemOpen(true);
  };

  const handleEdit = async (item) => {
    setEditingItem(item);
    setItemData({
      item: item.item,
      name: item.name, // During editing, this should not be editable or even required
      description: item.description,
      sequence_number: item.sequence_number,
      price: item.price,
      picture: item.picture,
    });
    setImageFile(null); // Clear image file when creating a new item
    setIsAddItemOpen(true); // Open the SideBox for editing
  };

  const handleCloseSideBox = () => {
    setIsAddItemOpen(false);
    setEditingItem(null);
  };

  // Define the cleanPicturePath function
  const cleanPicturePath = (path) => {
    const baseURL = "https://nyc3.digitaloceanspaces.com/kiwikiosk/media/";
    if (!path || path.trim() === "") {
      return "default.jpg";
    }
    if (path.startsWith(baseURL)) {
      return path.substring(baseURL.length);
    } else {
      // If the path doesn't contain the baseURL, attempt to remove any protocol and domain
      const urlParts = path.split(/https?:\/\/[^/]+\//);
      return urlParts[urlParts.length - 1];
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "kiwikiosk_items");

    try {
      const response = await API.post("/images/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fullUrl = response.data.url;
      const relativePath = cleanPicturePath(fullUrl);
      //console.log("Image replaced. Full URL:", fullUrl, "Relative Path:", relativePath);

      return relativePath;
    } catch (error) {
      toast({
        title: "Image Upload Failed.",
        description: "Unable to upload the image.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

  const handleImageReplace = async (imageKey, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "kiwikiosk_items");

    try {
      const encodedImageKey = encodeURIComponent(imageKey);
      //console.log('Replacing image with imageKey:', imageKey);
      const response = await API.put(`/images/${encodedImageKey}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fullUrl = response.data.url;
      const relativePath = cleanPicturePath(fullUrl);
      //console.log("Image replaced. Full URL:", fullUrl, "Relative Path:", relativePath);

      return relativePath;
    } catch (error) {
      toast({
        title: "Image Replace Failed.",
        description: "Unable to replace the image.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

  const handleSaveItem = async (updatedItemData, affectedCategories) => {
    try {
      // Set the latest item data
      let picturePath = itemData.picture;

      // Update the state with updatedItemData before processing
      const updatedItem = {
        ...itemData,
        ...updatedItemData,
      };

      // Handle image upload or replacement
      if (imageFile) {
        if (editingItem && picturePath) {
          const imageKey = cleanPicturePath(picturePath); // Clean the picturePath before extracting imageKey
          picturePath = await handleImageReplace(imageKey, imageFile);
        } else {
          picturePath = await handleImageUpload(imageFile);
        }
      } else {
        // No image uploaded
        if (!picturePath || picturePath.trim() === "") {
          // Set default image if picture is empty
          picturePath = "default.jpg";
        } else {
          picturePath = cleanPicturePath(picturePath);
        }
        // Use existing picturePath
      }

      // If an image path is available, store only the cleaned end path
      if (picturePath) {
        picturePath = cleanPicturePath(picturePath);
        updatedItem.picture = picturePath; // Only store the cleaned end path
      }

      // Convert price to a number
      updatedItem.price = parseFloat(updatedItem.price);

      // If creating a new item, assign sequence_number
      if (!editingItem) {
        // Fetch current items in the category to determine the next sequence_number
        const categoryId = updatedItem.category_names[0]; // Assuming one category per item
        const response = await API.get(`/categories/${categoryId}/items`);
        const itemsInCategory = response.data.data || [];
        const maxSequenceNumber = itemsInCategory.reduce(
          (max, item) =>
            item.sequence_number !== null &&
            item.sequence_number !== undefined &&
            item.sequence_number > max
              ? item.sequence_number
              : max,
          -1
        );
        updatedItem.sequence_number = maxSequenceNumber + 1;
      } else {
        // Preserve existing sequence_number when editing
        if (
          updatedItem.sequence_number === undefined ||
          updatedItem.sequence_number === null ||
          updatedItem.sequence_number === ""
        ) {
          updatedItem.sequence_number = itemData.sequence_number;
        }
      }

      if (editingItem) {
        await API.put(`/items/${editingItem.id}`, updatedItem);
        toast({
          title: "Item updated.",
          description: "The item was successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        delete updatedItem.sequence_number;

        updatedItem.name = updatedItem.item; // Auto-fill the name during creation
        await API.post("/items", updatedItem);
        toast({
          title: "Item created.",
          description: "The item was successfully created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      setCategoriesToRefetch((prev) => [
        ...new Set([...prev, ...affectedCategories]),
      ]);

      handleCloseSideBox(); // Close the SideBox after saving
    } catch (error) {
      console.error("Error saving category:", error);
      let errorMessage =
        error.response?.data?.message ||
        error.response?.data ||
        "Unable to save the item.";

      // Check and replace specific error messages
      if (errorMessage.includes("value too long for type")) {
        errorMessage = "The image file name is too long.";
      }

      toast({
        title: "An error occurred.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to reset categoriesToRefetch after refetching
  const handleCategoriesRefetched = () => {
    setCategoriesToRefetch([]);
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  return (
    <>
      <Box w={"full"} m={"-4"}>
        <Tabs colorScheme="black" onChange={handleTabChange} index={tabIndex}>
          <TabList
            position="sticky"
            top={0}
            bg={colorMode === "light" ? "white" : "gray.800"}
            zIndex={5}
            boxShadow="sm"
            justifyContent={"space-between"}
            overflowX={{ base: "auto", md: "inherit" }}
            //whiteSpace={{ base: "nowrap", md: "normal" }}
            px={{ base: 2, md: 4 }}
          >
            <Flex>
              <Tab fontSize={{ base: "sm", md: "md" }}>Overview</Tab>
              <Tab fontSize={{ base: "sm", md: "md" }}>Menus</Tab>
              <Tab fontSize={{ base: "sm", md: "md" }}>Categories</Tab>
              <Tab fontSize={{ base: "sm", md: "md" }}>Items</Tab>
              <Tab fontSize={{ base: "sm", md: "md" }}>Sold out items</Tab>
              <Tab fontSize={{ base: "sm", md: "md" }}>Customizations</Tab>
              <Tab fontSize={{ base: "sm", md: "md" }}>Schedules</Tab>
            </Flex>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex>
                <MotionBox
                  key={menuOverviewKey} // Add the key prop here
                  flex={1}
                  initial={{ width: "100%" }}
                  animate={{
                    width:
                      isAddItemOpen || editingItem
                        ? "calc(100% - 400px)"
                        : "100%",
                  }}
                  transition={{ type: "tween", duration: 0.5 }}
                  overflow="auto"
                >
                  <MenuOverview
                    refreshTrigger={menuOverviewKey} // Pass the key as a prop
                    onEditItem={handleEdit}
                    onSave={handleSaveItem}
                    handleAddItem={handleAddItem}
                    categoriesToRefetch={categoriesToRefetch} // Pass the categories to refetch
                    onCategoriesRefetched={handleCategoriesRefetched} // Pass the reset function
                  />
                </MotionBox>
                {(isAddItemOpen || editingItem) && (
                  <motion.div
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{
                      x: isAddItemOpen || editingItem ? 0 : "100%",
                      opacity: isAddItemOpen || editingItem ? 1 : 0,
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 80,
                      duration: 0.5,
                    }}
                    style={{ width: "400px", position: "relative" }}
                  >
                    <SideBox
                      isOpen={isAddItemOpen || editingItem}
                      onClose={handleCloseSideBox}
                      item={editingItem}
                      onSave={handleSaveItem}
                      imageFile={imageFile}
                      imageInputRef={imageInputRef}
                      handleImageChange={handleImageChange}
                      onCustomizationClick={handleCustomizationClick} // Pass the handler here
                    />
                  </motion.div>
                )}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Menus />
            </TabPanel>
            <TabPanel>
              <Categories onNewCategoryAdded={handleMenuOverviewRefresh} />
            </TabPanel>
            <TabPanel>
              <Items />
            </TabPanel>
            <TabPanel>
              <SoldOut />
            </TabPanel>
            <TabPanel>
              <Customizations
                selectedCustomizationId={selectedCustomizationId}
              />
            </TabPanel>
            <TabPanel>
              <Schedules />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
export default Menu;
