import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack,
  Input,
  Button,
  List,
  ListItem,
  Flex,
  Badge,
  useColorMode,
  useColorModeValue,
  SlideFade,
  Tag,
  TagLabel,
  TagCloseButton,
  Alert,
  AlertIcon,
  useToast,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Image,
  Spinner,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  EditIcon,
  CloseIcon,
  AddIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import API, { getMediaURL } from "../../../context/API";
import { GrUpdate } from "react-icons/gr";

const EditOptions = ({
  customizationData,
  handleBackClick,
  handleSubmit,
  handleInputChange,
  selectedItem,
  handleRefresh
}) => {
  const { colorMode } = useColorMode();
  const [items, setItems] = useState([]);
  const [customizations, setCustomizations] = useState([]);
  const toast = useToast();

  // States for Edit Options area
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // States for Create New Option area
  const [isCreatingNewOption, setIsCreatingNewOption] = useState(false);

  const [newOptionData, setNewOptionData] = useState({
    option: "",
    price: "",
    description: "",
  });
  const [isEditingOption, setIsEditingOption] = useState(false);
  const [editingOptionId, setEditingOptionId] = useState(null);

  // New loading state
  const [isLoading, setIsLoading] = useState(false);

  const optionsBorderColor = useColorModeValue("gray.200", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.700");

  const fetchOptions = async () => {
    try {
      const response = await API.get("/options"); 
      if (response.data.success) {
        setOptions(response.data.data);
        setFilteredOptions(response.data.data); 
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleSave = async () => {
    await handleSubmit();
    handleRefresh();
  }

  const handleSearch = (query) => {
    setQuery(query);
    if (query) {
      const results = options.filter((option) =>
        option.option.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredOptions(results);
    } else {
      setFilteredOptions(options);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (
      customizationData &&
      Array.isArray(customizationData.options) &&
      customizationData.options.length > 0
    ) {
      const sanitizedOptionIds = customizationData.options.map((option) => {
        if (typeof option === "object" && option !== null && "id" in option) {
          return option.id.toString();
        }
        return option.toString();
      });

      const hasObjects = customizationData.options.some(
        (option) => typeof option === "object"
      );

      if (hasObjects) {
        handleInputChange("options", sanitizedOptionIds);
      }

      const selectedOptionObjects = sanitizedOptionIds
        .map((optionId) =>
          options.find((option) => option.id.toString() === optionId.toString())
        )
        .filter((option) => option !== undefined); 

      setSelectedOptions(selectedOptionObjects);
    } else {
      setSelectedOptions([]); 
    }

    setIsLoading(false);
  }, [customizationData, options, handleInputChange, isCreatingNewOption]);

  const handleOptionSelect = (option) => {
    if (!selectedOptions.some((selected) => selected.id === option.id)) {
      const newSelectedOptions = [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);

      handleInputChange("options", [
        ...customizationData.options.map((id) => id.toString()),
        option.id.toString(),
      ]);

      setQuery(""); 
      setFilteredOptions(options); 
      setShowSuggestions(false); 
    }
  };

  const handleOptionRemove = (optionId) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.id !== optionId
    );
    setSelectedOptions(updatedOptions);

    const updatedOptionIds = customizationData.options
      .filter((id) => id !== optionId.toString())
      .map((id) => id.toString());

    handleInputChange("options", updatedOptionIds);
  };

  const handleNewOptionDataChange = (field, value) => {
    setNewOptionData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmitItem = async () => {
    const missingFields = [];
    if (!newOptionData.option || newOptionData.option.trim() === "") {
      missingFields.push("Option Name");
    }
    if (!newOptionData.price || isNaN(newOptionData.price)) {
      missingFields.push("Price");
    }
    if (!selectedItem) {
      missingFields.push("Customization ID");
    }

    if (missingFields.length > 0) {
      const formattedMissingFields = missingFields
        .map((field) => `• ${field}`)
        .join("\n");

      toast({
        title: "Missing Required Fields",
        description: `Please fill out the following required fields:\n${formattedMissingFields}`,
        status: "error",
        duration: 7000,
        isClosable: true,
        whiteSpace: "pre-line",
      });
      return;
    }

    const optionData = {
      option: newOptionData.option,
      price: parseFloat(newOptionData.price),
      description: newOptionData.description,
      customization: selectedItem.toString(), 
    };

    try {
      if (isEditingOption) {
        await API.put(`/options/${editingOptionId}`, optionData);
        toast({
          title: "Option updated.",
          description: "The option was successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        handleRefresh();
      } else {
        const response = await API.post("/options", optionData);
        if (response && response.data) {
          toast({
            title: "Option Created",
            description: "The new option has been created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          handleRefresh();
        } else {
          toast({
            title: "Error",
            description:
              response.data.message || "Failed to create the option.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      resetCreateNewOptionState();
      setIsCreatingNewOption(false);
    } catch (error) {
      console.error("Error saving option:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to save the option.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const resetCreateNewOptionState = () => {
    setNewOptionData({
      option: "",
      price: "",
      description: "",
    });
    setIsEditingOption(false);
    setEditingOptionId(null);
  };

  const handleBackFromCreateNewOption = () => {
    resetCreateNewOptionState();
    setIsCreatingNewOption(false);
  };

  const handleEditOption = (option) => {
    setIsCreatingNewOption(true);
    setIsEditingOption(true);
    setEditingOptionId(option.id);
    setNewOptionData({
      option: option.option,
      price: option.price !== undefined ? option.price.toString() : "",
      description: option.description || "",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".suggestions-box") &&
        !event.target.closest(".search-input")
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      p={4}
      flexDirection="column"
      width={"25%"}
      position={"fixed"}
      right={4}
      h={`calc(100vh - 120px)`}
      maxWidth={"inherit"}
      borderLeft="1px solid"
      borderColor={borderColor}
      overflowY={"auto"}
    >
      {!isCreatingNewOption && (
        <SlideFade in={!isCreatingNewOption} offsetY="20px">
          <HStack mb={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={handleBackClick}
              size="sm"
            />
            <Text
              cursor="pointer"
              onClick={handleBackClick}
              _hover={{ textDecoration: "underline" }}
              fontSize={{ base: "xs", md: "sm" }}
            >
              Customizations
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }}> / Options</Text>
          </HStack>

          <Heading size="md" mb={4} ml={2}>
            Edit Options
          </Heading>

          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              <Spinner size="xl" />
            </Box>
          ) : (
            <>
              <Input
                placeholder="Search options..."
                value={query}
                onChange={(e) => handleSearch(e.target.value)}
                onFocus={() => setShowSuggestions(true)} 
                variant="filled"
                borderRadius="full"
                mb={4}
                className="search-input"
              />

              <Box position="relative">
                {showSuggestions && filteredOptions.length > 0 && (
                  <Box
                    position="absolute"
                    bg={colorMode === "light" ? "white" : "gray.700"}
                    border="1px solid"
                    borderColor={optionsBorderColor}
                    borderRadius="md"
                    mt={2}
                    width="full"
                    zIndex="1"
                    maxHeight="150px"
                    overflowY="auto"
                    className="suggestions-box"
                  >
                    <List spacing={1}>
                      {filteredOptions.map((option) => {
                        const isSelected = selectedOptions.some(
                          (selected) => selected.id === option.id
                        );

                        return (
                          <ListItem
                            key={option.id}
                            p={2}
                            cursor="pointer"
                            _hover={{
                              bg:
                                colorMode === "light" ? "gray.200" : "gray.600",
                            }}
                            onClick={() => handleOptionSelect(option)}
                            display="flex"
                            justifyContent="space-between" 
                            alignItems="center"
                          >
                            <Text>{option.option}</Text>
                            {isSelected && (
                              <CheckIcon color="green.500" />
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                )}
              </Box>

              <Button
                width="full"
                variant="outline"
                justifyContent="space-between"
                mt={4}
                onClick={() => {
                  setIsCreatingNewOption(true);
                  resetCreateNewOptionState();
                }}
              >
                + Create a new option
              </Button>

              <VStack align="start" spacing={4} mt={4} flexGrow={1}>
                {selectedOptions.map((option, index) => (
                  <Box
                    key={index}
                    p={2}
                    width="full"
                    borderRadius="md"
                    border="1px solid"
                    borderColor={optionsBorderColor}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bg={colorMode === "light" ? "gray.50" : "gray.800"}
                  >
                    <Box>
                      <Text fontWeight="bold">{option.option}</Text>
                      <Text fontSize="sm">
                        {option.price !== undefined && !isNaN(option.price)
                          ? `$${option.price.toFixed(2)}`
                          : "$0.00"}
                      </Text>
                    </Box>
                    <HStack>
                      <IconButton
                        icon={<EditIcon />}
                        size="sm"
                        onClick={() => handleEditOption(option)}
                      />
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        onClick={() => handleOptionRemove(option.id)}
                      />
                    </HStack>
                  </Box>
                ))}
              </VStack>
              <Box mt={4} position="sticky" bottom={0} width="full">
                <Button
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  width="full"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </SlideFade>
      )}

      {isCreatingNewOption && (
        <SlideFade in={isCreatingNewOption} offsetY="20px">
          <HStack mb={4} flexWrap={"wrap"}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={handleBackFromCreateNewOption}
              size="sm"
            />
            <Text
              cursor="pointer"
              onClick={handleBackClick}
              _hover={{ textDecoration: "underline" }}
              fontSize={{ base: "xs", md: "sm" }}
            >
              Customizations
            </Text>
            <Text
              cursor="pointer"
              onClick={handleBackFromCreateNewOption}
              _hover={{ textDecoration: "underline" }}
              fontSize={{ base: "xs", md: "sm" }}
            >
              / Options
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }}>
              {isEditingOption ? " / Edit Item" : " / Create new option"}
            </Text>
          </HStack>

          <Heading size="md" mb={4} ml={2}>
            {isEditingOption ? "Edit Item" : "Create new option"}
          </Heading>
          <VStack spacing={4} mb={4} align="stretch">
            <FormControl isRequired>
              <FormLabel>Option Name</FormLabel>
              <Input
                placeholder="Option Name"
                value={newOptionData.option}
                onChange={(e) =>
                  handleNewOptionDataChange("option", e.target.value)
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                value={newOptionData.description}
                onChange={(e) =>
                  handleNewOptionDataChange("description", e.target.value)
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                placeholder="Price"
                type="number"
                value={newOptionData.price}
                onChange={(e) =>
                  handleNewOptionDataChange("price", e.target.value)
                }
              />
            </FormControl>
          </VStack>

          <Box mt={4} position="sticky" bottom={0} width="full">
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              width="full"
              onClick={handleSubmitItem} 
            >
              {isEditingOption ? "Save Changes" : "Create option"}
            </Button>
          </Box>
        </SlideFade>
      )}
    </Box>
  );
};

export default EditOptions;
