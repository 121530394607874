import React, {useEffect, useState} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {AddIcon, HamburgerIcon} from "@chakra-ui/icons";
import API, {getMediaURL} from "../../context/API"; // Import getMediaURL

const Menus = () => {
  const [menus, setMenus] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingMenuId, setDeletingMenuId] = useState(null);
  const cancelRef = React.useRef();
  const toast = useToast();

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenus = async () => {
    setLoading(true);
    try {
      const response = await API.get(`/menus`);
      // Map over the menus and their categories to ensure the pictures are set correctly
      const updatedMenus = response.data.map(menu => ({
        ...menu,
        categories: menu.categories.map(category => ({
          ...category,
          picture: getMediaURL(category.picture), // Use getMediaURL to construct the full image URL
        })),
      }));
      setMenus(updatedMenus);
    } catch (error) {
      setError("An error occurred while fetching menus.");
      setMenus([]); // Set to empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    setCurrentMenu(null);
    setIsEditing(true);
    onOpen();
  };

  const handleEdit = (menu) => {
    setCurrentMenu(menu);
    setIsEditing(true);
    onOpen();
  };

  const handleDeleteClick = (menuId) => {
    setDeletingMenuId(menuId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await API.delete(`/menus/${deletingMenuId}`);
      setMenus(menus.filter((menu) => menu.id !== deletingMenuId));
      toast({
        title: "Menu deleted.",
        description: "The menu has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting menu.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
    }
  };

  const handleSubmit = async () => {
    const formattedMenuData = {
      menu: currentMenu?.menu || "",
      is_active: currentMenu?.is_active || false,
    };

    try {
      if (currentMenu?.id) {
        await API.put(`/menus/${currentMenu.id}`, formattedMenuData);
        toast({
          title: "Menu updated.",
          description: "The menu has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await API.post("/menus", formattedMenuData);
        toast({
          title: "Menu created.",
          description: "The menu has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      fetchMenus();
      onClose();
    } catch (error) {
      toast({
        title: "Error saving menu.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("white", "gray.800");
  const bgColorAccordion = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <Flex justify="space-between" align="center" marginBottom="4">
        <Box>
          <Heading size={"xl"} letterSpacing={-2}>
            Menus
          </Heading>
          <Text fontSize={"sm"}>
            List of all available menus, click each item to expand and see more
          </Text>
        </Box>
        {/* <Button
          leftIcon={<AddIcon />}
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          variant="outline"
          onClick={handleCreate}
          isDisabled={true}
        >
          Create Menu
        </Button> */}
        <Button
          leftIcon={<AddIcon />}
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          variant="outline"
          onClick={handleCreate}
          display={{ base: "none", md: "flex" }} // Show on mobile
        >
          Create Menu
        </Button>
        <IconButton
          onClick={handleCreate}
          display={{ base: "flex", md: "none" }} // Show on mobile
          size="sm"
          bg="black"
          color="white"
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          borderRadius="full" // Rounded button for mobile
          icon={<AddIcon />}
        />
      </Flex>
      <Box p={4} bg={bgColor} borderRadius="md">
        <Accordion allowToggle>
          {menus.map((menu) => (
            <AccordionItem key={menu.id} border="none">
              <h2>
                <AccordionButton
                  _expanded={{ bg: bgColorAccordion }}
                  borderRadius="md"
                  mb={2}
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    display="flex"
                    alignItems="center"
                  >
                    <Icon as={HamburgerIcon} mr={2} />
                    <Text>{menu.menu}</Text>
                    {menu.is_active && (
                      <Badge ml={2} colorScheme="green">
                        Active
                      </Badge>
                    )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} borderRadius="md">
                {menu.categories.length ? (
                  menu.categories.map((category) => (
                    <Box
                      key={category.id}
                      mb={4}
                      p={4}
                      borderWidth="1px"
                      borderRadius="md"
                      bg={bgColorAccordion}
                    >
                      <Flex alignItems="center">
                        <Image
                          src={category.picture} // Now using the full URL for the image
                          alt={category.category}
                          boxSize="50px"
                          objectFit="cover"
                          borderRadius="md"
                          mr={4}
                          fallbackSrc="https://via.placeholder.com/50"
                        />
                        <Box>
                          <Text fontWeight="bold">{category.category}</Text>
                          <Text fontSize="sm" color={textColor}>
                            Sequence: {category.sequence_number}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  ))
                ) : (
                  <Text>No categories available</Text>
                )}
                <Flex mt={4} justifyContent="flex-end">
                  <Button
                    bg="black"
                    color={"white"}
                    _hover={{
                      color: "black",
                      bg: "white",
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                    variant="outline"
                    size="sm"
                    onClick={() => handleEdit(menu)}
                    mr={2}
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleDeleteClick(menu.id)}
                  >
                    Delete
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>

      {/* Modal for Creating/Editing Menu */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "full", md: "4xl" }}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentMenu ? "Edit Menu" : "Create Menu"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="menu" mb="4">
              <FormLabel>Menu Name</FormLabel>
              <Input
                placeholder="Enter menu name"
                value={currentMenu?.menu || ""}
                onChange={(e) =>
                  setCurrentMenu({ ...currentMenu, menu: e.target.value })
                }
              />
            </FormControl>
            <FormControl id="is_active" mb="4">
              <FormLabel>Active Status</FormLabel>
              <Checkbox
                size="lg"
                rounded="md"
                isChecked={currentMenu?.is_active}
                onChange={(e) =>
                  setCurrentMenu({
                    ...currentMenu,
                    is_active: e.target.checked,
                  })
                }
              >
                Is Active
              </Checkbox>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              onClick={handleSubmit}
              ml={3}
            >
              {currentMenu ? "Update Menu" : "Create Menu"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert dialog box for deleting confirmation */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Menu
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Menus;
