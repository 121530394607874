import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Icon,
  Spinner,
  Tooltip,
  useColorModeValue,
  Heading,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  CheckboxGroup,
  Checkbox,
  Stack,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  VStack,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  TimeIcon,
  InfoIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

import API from "../../context/API";
import React, { useState, useEffect } from "react";

const Schedules = () => {
  const [schedules, setSchedules] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [scheduleData, setScheduleData] = useState({
    day_names: [],
    start_time: "",
    end_time: "",
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deletingScheduleId, setDeletingScheduleId] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    setLoading(true);
    try {
      const response = await API.get(`/schedules`);
      setSchedules(response.data);
    } catch (error) {
      setError("An error occurred while fetching schedules.");
      setSchedules([]); // Set to empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScheduleData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDayNamesChange = (dayNames) => {
    setScheduleData((prevData) => ({ ...prevData, day_names: dayNames }));
  };

  const handleCreate = () => {
    setIsEdit(false);
    setScheduleData({ day_names: [], start_time: "", end_time: "" });
    onOpen();
  };

  const handleEdit = (schedule) => {
    setIsEdit(true);
    setSelectedSchedule(schedule.id);
    setScheduleData({
      day_names: schedule.day_names,
      start_time: schedule.start_time,
      end_time: schedule.end_time,
    });
    onOpen();
  };

  const handleDelete = (scheduleId) => {
    setDeletingScheduleId(scheduleId);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await API.delete(`/schedules/${deletingScheduleId}`);
      toast({
        title: "Schedule deleted.",
        description: "The schedule has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchSchedules();
    } catch (error) {
      toast({
        title: "Error deleting schedule.",
        description: "An error occurred while deleting the schedule.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (isEdit) {
        await API.put(`/schedules/${selectedSchedule}`, scheduleData);
        toast({
          title: "Schedule updated.",
          description: "The schedule has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        await API.post(`/schedules`, scheduleData);
        toast({
          title: "Schedule created.",
          description: "The schedule has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      fetchSchedules();
      onClose();
    } catch (error) {
      toast({
        title: "Error saving schedule.",
        description: "An error occurred while saving the schedule.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("white", "gray.800");
  const bgColorAccordian = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <Flex justify="space-between" align="center" marginBottom="4">
        <VStack align={"flex-start"} spacing={"0"}>
          <Heading size={"xl"} letterSpacing={-2}>
            Schedules
          </Heading>
          <Text fontSize={"sm"}>
            All the available schedules. See details, create, delete or update
            existing schedules.
          </Text>
        </VStack>
        <Button
          leftIcon={<AddIcon />}
          bg="black"
          color={"white"}
          _hover={{
            color: "black",
            bg: "white",
            borderWidth: "1px",
            borderColor: "gray.300",
          }}
          variant="outline"
          onClick={handleCreate}
        >
          Create Schedule
        </Button>
      </Flex>
      <Box p={4} bg={bgColor} borderRadius="md">
        <Accordion allowToggle>
          {schedules.map((schedule) => (
            <AccordionItem key={schedule.id} border="none">
              <h2>
                <AccordionButton
                  _expanded={{ bg: bgColorAccordian }}
                  borderRadius="md"
                  mb={2}
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    display="flex"
                    alignItems="center"
                  >
                    <Icon as={CalendarIcon} mr={2} />
                    {schedule.day_names.length ? (
                      schedule.day_names.join(", ")
                    ) : (
                      <Tooltip
                        label="No specific days, applies to all days"
                        aria-label="No Days Tooltip"
                      >
                        <Box display="flex" alignItems="center">
                          <Text mr={1}>All Days</Text>
                          <Icon as={InfoIcon} />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} borderRadius="md">
                <Text display="flex" alignItems="center" mb={2}>
                  <Icon as={TimeIcon} mr={2} />
                  {schedule.start_time} - {schedule.end_time}
                </Text>
                <Text fontSize="sm" color={textColor}>
                  Created at: {new Date(schedule.created_at).toLocaleString()}
                </Text>
                <Text fontSize="sm" color={textColor}>
                  Updated at: {new Date(schedule.updated_at).toLocaleString()}
                </Text>
                <Flex mt={4} justifyContent="flex-end">
                  <Button
                    leftIcon={<EditIcon />}
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => handleEdit(schedule)}
                    mx={"2"}
                  >
                    Edit
                  </Button>
                  <Button
                    leftIcon={<DeleteIcon />}
                    colorScheme="red"
                    variant="outline"
                    onClick={() => handleDelete(schedule.id)}
                    mx={"2"}
                  >
                    Delete
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>

      {/* Modal for Creating/Editing Schedule */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEdit ? "Edit Schedule" : "Create Schedule"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="day_names" mb="4">
              <FormLabel>Select Days</FormLabel>
              <CheckboxGroup
                colorScheme="green"
                value={scheduleData.day_names}
                onChange={handleDayNamesChange}
              >
                <Stack spacing={4} direction="row">
                  <Checkbox value="MONDAY">Monday</Checkbox>
                  <Checkbox value="TUESDAY">Tuesday</Checkbox>
                  <Checkbox value="WEDNESDAY">Wednesday</Checkbox>
                  <Checkbox value="THURSDAY">Thursday</Checkbox>
                  <Checkbox value="FRIDAY">Friday</Checkbox>
                  <Checkbox value="SATURDAY">Saturday</Checkbox>
                  <Checkbox value="SUNDAY">Sunday</Checkbox>
                </Stack>
              </CheckboxGroup>
            </FormControl>
            <FormControl id="start_time" mb="4">
              <FormLabel>Start Time</FormLabel>
              <Input
                type="time"
                name="start_time"
                value={scheduleData.start_time}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="end_time" mb="4">
              <FormLabel>End Time</FormLabel>
              <Input
                type="time"
                name="end_time"
                value={scheduleData.end_time}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              leftIcon={<AddIcon />}
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              mr={3}
              onClick={handleSubmit}
            >
              {isEdit ? "Update" : "Create"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Alert Dialog for Deletion Confirmation */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={undefined}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Schedule
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsDeleteDialogOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Schedules;
